import { Box, Button, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import BasicDatePicker from "./BasicDatePicker";
import * as Parse from "parse";
import { tokens } from "../theme";
import dayjs from 'dayjs';
import { Spinner } from "react-bootstrap";
import { DataGridPro } from '@mui/x-data-grid-pro';

const MetricsDashboard = () => {
    const theme = useTheme();
    const currentUser = Parse.User.current();
    const [loading, setLoading] = useState(false);
    const colors = tokens(theme.palette.mode);
    const [selectedFromDate, setSelectedFromDate] = useState(dayjs(new Date("2024-01-01")));
    const [selectedToDate, setSelectedToDate] = useState(dayjs());
    const [allQuotationProducts, setAllQuotationProducts] = useState([]);
    const [productsWithQuantity, setProductsWithQuantity] = useState([]);
    const [sortModel, setSortModel] = useState([
        {
          field: 'quantity',
          sort: 'desc',
        },
      ]);

    const calculateAveragePercentage = (numbers) => {
        if (!Array.isArray(numbers) || numbers.length === 0) {
          return 0;
        }
      
        const sum = numbers.reduce((acc, num) => acc + num, 0);
        const average = sum / numbers.length;
        const averagePercentage = (average).toFixed(2); // Limit to two decimal places
      
        return parseFloat(averagePercentage);
    };

    const calculatePercentageOfQuantity = (quantity, percentage) => {
        const calculatedPercentage = (quantity * (percentage/100)).toFixed(2); // Round off to 2 decimal places
        return parseFloat(calculatedPercentage);
      };

    const columns = [
        {
          field: "name",
          headerName: "Product Name",
          width: 120,
          align: "center", // Aligns content to the center
          valueGetter: (params) => `${params?.row.part.name || ""}`,
        },
        {
            field: "descriptiom",
            headerName: "Product Description",
            width: 250,
            align: "center", // Aligns content to the center
            valueGetter: (params) => `${params?.row.part.className === "PalletRackingParts" ? params?.row.part.description : params?.row.part.className === "PalletRackingPartsUsed" ? params?.row.part.description : `${params?.row.part.width} wide - ${params?.row.part?.height} high - ${params?.row.part?.depth} deep` || "N/A"}`,
        },
        // {
        //     field: "quantity",
        //     headerName: "Quantity",
        //     align: "center", // Aligns content to the center
        //     valueGetter: (params) => parseInt(params.row.quantity || 0),
        //     type: "number", // Specify the data type for sorting
        // },
        {
            field: "duty",
            headerName: "Product Duty",
            width: 120,
            align: "center", // Aligns content to the center
            valueGetter: (params) => `${params?.row.part.duty || "N/A"}`,
        },
        {
            field: "finish",
            headerName: "Product Finish",
            width: 120,
            align: "center", // Aligns content to the center
            valueGetter: (params) => `${params?.row.part.finish || "N/A"}`,
        },
        // {
        //   field: "id",
        //   headerName: "Quotation(s) Number",
        //   width: 200,
        //   align: "center", // Aligns content to the center
        //   valueGetter: (params) => `${joinWithCommasExceptLast(params.row.quotations) || ""}`,
        // },
        {
          field: "stage",
          headerName: "Stage",
          width: 90,
          align: "center", // Aligns content to the center
          valueGetter: (params) => `${params.row.part.quotationStatus !== "deposit" ? "open" : "deposit" || ""}`,
        },
        {
            field: 'quantityDeposit',
            headerName: 'Qty in Pipeline (Packing)',
            width: 170,
            align: "center", // Aligns content to the center
            valueGetter: (params) => (params.row.part.quotationStatus === "deposit" ? parseInt(params.row.quantity || 0) : "-"),
            type: "number", // Specify the data type for sorting
        },
        {
            field: 'quantityOpen',
            headerName: 'Qty quoted (Open)',
            width: 140,
            align: "center", // Aligns content to the center
            valueGetter: (params) => (params.row.part.quotationStatus !== "deposit" ? parseInt(params.row.quantity || 0) : "-"),
            type: "number", // Specify the data type for sorting
        },
        {
            field: "forecast",
            headerName: "Average Forecast %",
            width: 135,
            align: "center", // Aligns content to the center
            valueGetter: (params) => `${calculateAveragePercentage(params.row.forecast)}%`,
        },
        {
            field: "forecastedQty",
            headerName: "Forecasted Qty",
            width: 135,
            align: "center", // Aligns content to the center
            valueGetter: (params) => `${params.row.part.quotationStatus !== "deposit" ? calculatePercentageOfQuantity(parseInt(params.row.quantity || 0) , calculateAveragePercentage(params.row.forecast)) : 0}`,
        },
        {
            field: "stock",
            headerName: "Actual Stock in hand",
            width: 135,
            align: "center", // Aligns content to the center
            valueGetter: (params) => `${params.row.part.inventoryQuantity}`,
        },
        {
            field: "stockLeft",
            headerName: "Stock after forecast",
            width: 135,
            align: "center", // Aligns content to the center
            valueGetter: (params) => `${params.row.part.inventoryQuantity - calculatePercentageOfQuantity(parseInt(params.row.quantity || 0) , calculateAveragePercentage(params.row.forecast))}`,
        },
        // {
        //     field: "percentage",
        //     headerName: "Product Item/Stock %",
        //     width: 180,
        //     align: "center", // Aligns content to the center
        //     valueGetter: (params) => `${params.row.part.inventoryQuantity * 100 > 0 ? (parseFloat(params.row.quantity/params.row.part.inventoryQuantity * 100)).toFixed(2) + "%" : "0 in inventory" || "N/A"}`,
        //   },
        // {
        //   field: "quotationTotal",
        //   headerName: "Quotation Total",
        //   width: 120,
        //   align: "center", // Aligns content to the center
        //   valueGetter: (params) =>
        //     `${params.row.part.quotationTotal || ""}`,
        // },
      ];
    

    const queryQuotationsRelativeToDateRange = async () => {
        try {
            setLoading(true);
            const quotationsQuery = new Parse.Query(Parse.Object.extend("Quotations"));
            quotationsQuery.notEqualTo('status', 'cancelled');
            quotationsQuery.lessThanOrEqualTo("createdAt", new Date(selectedToDate));
            quotationsQuery.greaterThanOrEqualTo("createdAt", new Date(selectedFromDate));
            const quotations = await quotationsQuery.find();
            const allPartsDictionary = {};
            quotations.map((quote) => {
                return allPartsDictionary[quote.id] ? allPartsDictionary[quote.id].parts.push(quote.get('quotationParts')) : allPartsDictionary[quote.id] =  {quote: quote, parts: quote.get('quotationParts')};
            });
    
            let newQuotations = {}
            Object.keys(allPartsDictionary).map((key) => {
                let updatedList = []
                allPartsDictionary[key].parts.map(object => {
                    return updatedList.push({...object, quotationId: key, quotationStatus: allPartsDictionary[key].quote.get("status"), quotationNumber: allPartsDictionary[key].quote.get("quotationNumber"), winProbability: allPartsDictionary[key].quote.get("winProbability"), quotationTotal: allPartsDictionary[key].quote.get("totalPrice")})
                })
                return newQuotations[key] = updatedList;
            });
            setAllQuotationProducts(Object.values(newQuotations).flat());   
        } catch (error) {
            console.log('error: ', error);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (allQuotationProducts.length > 0) {
            setLoading(false);
            let objectMap = {};
            allQuotationProducts.map((product, index) => {
                console.log(product)
                if (objectMap[product.partID+"_open"] !== undefined && product.quotationStatus !== "deposit"){
                    objectMap[product.partID+"_open"].quantity = objectMap[product.partID+"_open"].quantity + product.quantity;
                    objectMap[product.partID+"_open"].forecast = [...objectMap[product.partID+"_open"].forecast, product?.winProbability];
                    return objectMap[product.partID+"_open"].quotations = [...objectMap[product.partID+"_open"].quotations, product.quotationNumber]
                } else if (objectMap[product.partID+"_open"] === undefined && product.quotationStatus !== "deposit") {
                    return objectMap[product.partID+"_open"] = {part: product, quantity: product.quantity, id: index, quotations: [product.quotationNumber], forecast: [product?.winProbability]}
                } else if (objectMap[product.partID+"_deposit"] !== undefined && product.quotationStatus === "deposit") {
                    objectMap[product.partID+"_deposit"].quantity = objectMap[product.partID+"_deposit"].quantity + product.quantity;
                    objectMap[product.partID+"_deposit"].forecast = [...objectMap[product.partID+"_deposit"].forecast, product?.winProbability];
                    return objectMap[product.partID+"_deposit"].quotations = [...objectMap[product.partID+"_deposit"].quotations, product.quotationNumber]
                } else if (objectMap[product.partID+"_deposit"] === undefined && product.quotationStatus === "deposit") {
                    return objectMap[product.partID+"_deposit"] = {part: product, quantity: product.quantity, id: index, quotations: [product.quotationNumber], forecast: [product?.winProbability]}
                }
            });
            console.log('objectMap: ', objectMap);
            setProductsWithQuantity(objectMap);
        }
    }, [allQuotationProducts]);

    useEffect(() => {
        if (Object.values(productsWithQuantity).length > 0) {
            const individualPartsOcurrances = {};
            Object.values(productsWithQuantity).map((value) => {
                if (individualPartsOcurrances[value.part.partID+"_open"] && value.part.quotationStatus === "open") {
                    return individualPartsOcurrances[value.part.partID+"_open"] = {quotations: [...individualPartsOcurrances[value.part.partID+"_open"].quotations, value.part.quotationId]}
                } else if (!individualPartsOcurrances[value.part.partID+"_open"] && value.part.quotationStatus === "open") {
                    return individualPartsOcurrances[value.part.partID+"_open"] = {quotations: [value.part.quotationId]}
                }
                else {
                }
            })
        };
    }, [productsWithQuantity])

    return (
        <Box>
            {(currentUser.get('email') === "sunil@storemax.com.au" || currentUser.get('email') === "wahabtariq55@gmail.com") && <Box>
                <Box display={'flex'} >
                    <Box mx={1}>
                        <BasicDatePicker date={selectedFromDate} setDate={setSelectedFromDate} label={"From Date"}/>
                    </Box>
                    <Box mx={1}>
                        <BasicDatePicker date={selectedToDate} setDate={setSelectedToDate} label={"To Date"}/>
                    </Box>
                    <Box mx={1}>
                        <Button
                            sx={{
                              backgroundColor: colors.blueAccent[700],
                              color: colors.grey[100],
                              fontSize: "14px",
                              fontWeight: "bold",
                              padding: "5px 20px",
                              mt: '1em'
                            }}
                            onClick={queryQuotationsRelativeToDateRange}
                          >
                            GO
                          </Button>
                    </Box>
                </Box>
                {!loading ? <Box width={"100%"} display={'flex'} justifyContent={'center'}>
                    {Object.values(productsWithQuantity).length > 0 && <>
                        <DataGridPro
                        sx={{
                            width: '80%',
                            margin: '20px',
                            // disable cell selection style
                            '.MuiDataGrid-cell:focus': {
                                outline: 'none'
                            },
                            // pointer cursor on ALL rows
                            '& .MuiDataGrid-row:hover': {
                                cursor: 'pointer'
                            },
                        }}
                        slotProps={{
                            filterPanel: {
                                sx: {
                                "& .MuiButtonBase-root": {
                                    backgroundColor: 'wheat'
                                }
                                }
                            }
                        }}
                        pagination
                        rows={Object.values(productsWithQuantity)}
                        columns={columns}
                        sortModel={sortModel}
                        onSortModelChange={(model) => setSortModel(model)}
                        onRowClick={() => console.log('abc')}
                        />
                    </>}
                </Box> : 
                <Box textAlign={'center'}>
                    <Spinner animation="border" role="status" size='sm' variant="primary"/>
                </Box>}
            </Box>}
        </Box>
    )
}

export default MetricsDashboard;