import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box } from '@mui/material';
import { useState } from 'react';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const KEY_FILTER = 'datagrid_filter';

export default function BasicTable(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalData, setModalData] = React.useState();
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({pageSize: 20, page: 0});
  const [filterValues, setFilterValues] = useState(() => {
  const storedFilter = localStorage.getItem(KEY_FILTER);
  return storedFilter ? JSON.parse(storedFilter) : { items: [] }; // Ensure default structure
  });
  const [totalPrice, setTotalPrice] = useState(0);
  

  const handleQuotationItemDetailRouting = (event) => {
    navigate(`/quotation/${event.id}/${event.row?.get('quotationType')}`)
  };

  const handleClientClick = (event) => {
    navigate(`/client/${event.id}`)
  };

  const saveFilterValues = (values) => {
    localStorage.setItem(KEY_FILTER, JSON.stringify(values));
  };

  const columns = [
    {
      field: "quotationNumber",
      headerName: "Quote No.",
      width: 120,
      valueGetter: (params) => `${params.row?.get("quotationNumber") || ""}`,
    },
    {
      field: "dateCreated",
      headerName: "Date Created",
      width: 130,
      valueGetter: (params) =>
        `${params.row?.get("createdAt").toString().slice(0, 15) || ""}`,
    },
    {
      field: "quotationType",
      headerName: "Quotation Type",
      width: 200,
      valueGetter: (params) => `${params.row?.get("quotationType")?.join(", ") || ""}`,
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      valueGetter: (params) => `${params.row?.get("status") || ""}`,
    },
    {
      field: "client",
      headerName: "Client",
      width: 200,
      valueGetter: (params) =>
        `${params.row?.get("client") ? params.row?.get("client")?.get("companyName") : params.row?.get("selectedClient") ? params.row?.get("selectedClient")?.data?.companyName : "" || ""}`,
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      width: 120,
      groupable: false,
      valueGetter: (params) => `${params.row?.get("totalPrice") === "0.00" && params.row?.get("emptyQuotation") ? params.row?.get("emptyQuotationTotal") : params.row?.get("totalPrice")}`,
    },
    {
      field: "createdBy",
      headerName: "Created by",
      width: 120,
      valueGetter: (params) =>
        `${params.row?.get("createdBy")?.get("username") || ""}`,
    },
    {
      field: "updatedBy",
      headerName: "Last Updated by",
      width: 120,
      valueGetter: (params) =>
        `${params.row?.get("updatedBy")?.get("username") || ""}`,
    },
  ];

  const clientColumns = [
    {
      field: "dateCreated",
      headerName: "Date Created",
      width: 130,
      valueGetter: (params) =>
        `${params.row?.get("createdAt").toString().slice(0, 15) || ""}`,
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 120,
      valueGetter: (params) => `${params.row?.get("firstName") || ""}`,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 120,
      valueGetter: (params) => `${params.row?.get("lastName") || ""}`,
    },
    {
      field: "companyName",
      headerName: "companyName",
      width: 130,
      valueGetter: (params) =>
        `${params.row?.get("companyName")|| ""}`,
    },
    {
      field: "address",
      headerName: "Address",
      width: 120,
      valueGetter: (params) => `${params.row?.get("address") || ""}`,
    },
    {
      field: "suburb",
      headerName: "suburb",
      width: 90,
      valueGetter: (params) => `${params.row?.get("suburb") || ""}`,
    },
    {
      field: "email",
      headerName: "Email",
      width: 120,
      valueGetter: (params) =>
        `${params.row?.get("email") || ""}`,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 120,
      valueGetter: (params) =>
        `${params.row?.get("phoneNumber") || ""}`,
    },
  ];
  
  const rows = props.data;
  
  const renderFooter = () => (
    <Box display={'flex'} justifyContent={'center'}>
      <span className='my-2'>Total Price: ${totalPrice.toFixed(2)}</span>
    </Box>
  );

  React.useEffect(() => {
    // Function to hide the MUI X license warning
    const hideMuiXWarning = () => {
      // Try to find the element by its text content
      const warningElement = Array.from(document.body.getElementsByTagName('*'))
        .find(element => element.textContent === 'MUI X Missing license key');
      
      // If the element is found, hide it
      if (warningElement) {
        warningElement.style.display = 'none';
      }
    };

    // Call the function to hide the warning
    hideMuiXWarning();

    // Optional: Set up a MutationObserver to handle dynamically added elements
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.addedNodes.length) {
          hideMuiXWarning();
        }
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    // Cleanup function
    return () => {
      observer.disconnect();
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <div>
      {props.data.length > 0 ? (
        <Box>
          {!props.clients ? (
            <div>
              <DataGridPro
                sx={{
                  // disable cell selection style
                  ".MuiDataGrid-cell:focus": {
                    outline: "none"
                  },
                  // pointer cursor on ALL rows
                  "& .MuiDataGrid-row:hover": {
                    cursor: "pointer",
                  },
                  padding: 2,
                  boxShadow: 3,
                  border: 0,
                  borderColor: 'primary.light',
                  '& .MuiDataGrid-cell:hover': {
                    color: '#20c997',
                  },
                  fontSize: '18px'
                }}
                slotProps={{
                  filterPanel: {
                      sx: {
                      "& .MuiButtonBase-root": {
                          backgroundColor: 'wheat'
                      }
                      }
                  }
                }}
                rows={rows}
                columns={columns}
                pagination
                paginationModel={paginationModel}
                onPaginationModelChange={(e) => setPaginationModel(e)}
                onRowClick={handleQuotationItemDetailRouting}
                filterModel={filterValues || { items: [] }}
                onFilterModelChange={(model) => {
                  setFilterValues(model);
                  saveFilterValues(model);
                  setTotalPrice(rows.reduce((total, row) => total + parseFloat(row?.get("totalPrice") === "0.00" && row?.get("emptyQuotation") ? row?.get("emptyQuotationTotal") : row?.get("totalPrice")), 0));
                }}
                slots={{
                  toolbar: renderFooter
                }}
                onStateChange={(state) => {
                  const visibleRows = state.filter.filteredRowsLookup;
                  let visibleItems = [];
                  for (const [id, value] of Object.entries(visibleRows)) {
                    if (value === true) {
                      visibleItems.push(id);
                    }
                  }
                  const res = rows.filter((item) => visibleItems.includes(item.id));
                  setTotalPrice(res.reduce((total, row) => total + parseFloat(row?.get("totalPrice") === "0.00" && row?.get("emptyQuotation") ? row?.get("emptyQuotationTotal") : row?.get("totalPrice")), 0));
                }}
              />
            </div>
          ) : (
            <DataGridPro
              sx={{
                // disable cell selection style
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                // pointer cursor on ALL rows
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
              slotProps={{
                filterPanel: {
                    sx: {
                    "& .MuiButtonBase-root": {
                        backgroundColor: 'wheat'
                    }
                    }
                }
              }}
              pageSizeOptions={[10, 25, 50]}
              pagination
              pageSize={50}
              rowCount={rows.length} // Provide the total number of rows for pagination
              rows={rows}
              columns={clientColumns}
              onRowClick={handleClientClick}
              filterModel={filterValues} // Set filterModel from state
              onFilterModelChange={(model) => {
                setFilterValues(model);
                saveFilterValues(model);
              }}

            />
          )}
        </Box>
      ) : (
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <div className="sk-folding-cube">
            <div className="sk-cube1 sk-cube"></div>
            <div className="sk-cube2 sk-cube"></div>
            <div className="sk-cube4 sk-cube"></div>
            <div className="sk-cube3 sk-cube"></div>
          </div>
        </Box>
      )}
    </div>
  );
}