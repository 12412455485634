import * as React from "react";
import Parse from 'parse';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, FormControl, Icon, MenuItem, Select, useTheme } from "@mui/material";
import { tokens } from "../theme";
import Tab from "./TabComponent";
import DownloadQuotation from "./DownloadQuotationDocument";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { calculateOverallTotal } from "./utils";
import Picklist from "./PickListDocument";
import VerifiedIcon from '@mui/icons-material/Verified';
import { quotationStatuses, quotationStatusesOperations } from "./QuotationItemDetailComponent";

export default function RevisionAccordion(props) {
  const [revision, setRevision] = useState(props.revision);
  const finalizeRevision = props.finalizeRevision
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [activeSection, setActiveSection] = React.useState(1);
  const [totalPriceRelativeToSection, setTotalPriceRelativeToSection] = React.useState({});
  const [fileName, setFileName] = useState('');
  const [file, setQuotationFile] = useState();
  const [discountPercentage, setDiscountPercentage] = useState({ 1: '' });
  const [marginPercentage, setMarginPercentage] = useState({ 1: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [totalMarginalizedPriceRelativeToSectionQuotation, setTotalMarginalizedPriceRelativeToSectionQuotation] = useState({});
  const [totalDiscountedPriceRelativeToSectionQuotation, setTotalDiscountedPriceRelativeToSectionQuotation] = useState({});
  const [emailSentCount, setEmailSentCount] = useState([]);
  const [revisionSections, setRevisionSections] = useState([]);
  const [quotationStatus, setQuotationStatus] = useState(revision.get('status') || "open");

  const handleTabClick = (tabIndex) => {
    setActiveSection(tabIndex);
  };

  const overallTotal = calculateOverallTotal(revisionSections);

  React.useEffect(() => {
    const totalPricePerSection = {};
    if (revision) {
      getEmailSentCount();
      setMarginPercentage(revision?.get("quotationMargin"));
      setDiscountPercentage(revision?.get("quotationDiscount"));
      setRevisionSections(revision?.get('sections') ?? [])
      revision.get('quotationParts').map((part) => {
        return totalPricePerSection[part.section] = totalPricePerSection[part.section] ? totalPricePerSection[part.section] + (part.price * part.quantity) : (part.price * part.quantity)
      })
      revision.get('miscParts')?.map((part) => {
        return totalPricePerSection[part.section] = totalPricePerSection[part.section] ? totalPricePerSection[part.section] + (part.part.price * part.quantity) : (part.part.price * part.quantity)
      });
      if (revision.get('quotationMargin') === undefined || revision.get('quotationMargin') === null || revision.get('quotationMargin') === '') {
      }
      else {
        const totalMarginPricePerSection = {};
        Object.keys(totalPriceRelativeToSection).map((k) => {
          return k === activeSection ? totalMarginPricePerSection[k] = (totalPriceRelativeToSection[k] + (totalPriceRelativeToSection[k] / 100) * (revision.get('quotationMargin'))) : totalMarginPricePerSection[k] = totalPriceRelativeToSection[k]
        });
        totalMarginPricePerSection[activeSection] = (totalPriceRelativeToSection[activeSection] + (totalPriceRelativeToSection[activeSection] / 100) * (revision.get('quotationMargin')[activeSection]))
        setTotalMarginalizedPriceRelativeToSectionQuotation(totalMarginPricePerSection);
      }
      if (revision.get('quotationDiscount') === undefined || revision.get('quotationDiscount') === null || revision.get('quotationDiscount') === '') {
      }
      else {
        const totalDiscountedPricePerSection = {};
        Object.keys(totalPriceRelativeToSection).map((k) => {
          return k === activeSection ? totalDiscountedPricePerSection[k] = (totalPriceRelativeToSection[k] - (totalPriceRelativeToSection[k] / 100) * (revision.get('quotationDiscount'))) : totalDiscountedPricePerSection[k] = totalPriceRelativeToSection[k]
        });
        totalDiscountedPricePerSection[activeSection] = (totalPriceRelativeToSection[activeSection] - (totalPriceRelativeToSection[activeSection] / 100) * (revision.get('quotationDiscount')[activeSection]))
        setTotalDiscountedPriceRelativeToSectionQuotation(totalDiscountedPricePerSection);
      }
      setTotalPriceRelativeToSection(totalPricePerSection);
    }
  }, [revision]);

  React.useEffect(() => {
    if (marginPercentage === undefined || marginPercentage === null || marginPercentage === '') {
    }
    else {
      const totalMarginPricePerSection = {};
      Object.keys(totalPriceRelativeToSection).map((k) => {
        return k === activeSection ? totalMarginPricePerSection[k] = (totalPriceRelativeToSection[k] + (totalPriceRelativeToSection[k] / 100) * (marginPercentage)) : totalMarginPricePerSection[k] = totalPriceRelativeToSection[k]
      });
      totalMarginPricePerSection[activeSection] = (totalPriceRelativeToSection[activeSection] + (totalPriceRelativeToSection[activeSection] / 100) * (marginPercentage[activeSection]))
      setTotalMarginalizedPriceRelativeToSectionQuotation(totalMarginPricePerSection);
    }
  }, [marginPercentage]);

  React.useEffect(() => {
    if (discountPercentage === undefined || discountPercentage === null || discountPercentage === '') {
    }
    else {
      const totalDiscountedPricePerSection = {};
      Object.keys(totalPriceRelativeToSection).map((k) => {
        return k === activeSection ? totalDiscountedPricePerSection[k] = (totalPriceRelativeToSection[k] - (totalPriceRelativeToSection[k] / 100) * (discountPercentage)) : totalDiscountedPricePerSection[k] = totalPriceRelativeToSection[k]
      });
      totalDiscountedPricePerSection[activeSection] = (totalPriceRelativeToSection[activeSection] - (totalPriceRelativeToSection[activeSection] / 100) * (discountPercentage[activeSection]))
      setTotalDiscountedPriceRelativeToSectionQuotation(totalDiscountedPricePerSection)
    }
  }, [discountPercentage]);

  const getEmailSentCount = async () => {
    const emailCount = await revision.relation('emails').query().find();
    setEmailSentCount(emailCount);
  }

  const fileToDataUri = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result)
    };
    return reader.readAsDataURL(file);
  })

  const handleOnChange = async (e) => {
    const file = e.target.files[0];
    setFileName(e.target.files[0].name);
    const fileURI = await fileToDataUri(file);
    var base64 = fileURI;
    var parseFile = new Parse.File(`${file.name.split('.')[0].replace(/ *\([^)]*\) */g, "").trim()}`, { uri: base64 });
    setQuotationFile(parseFile);
  };

  const handleQuotationStatusChange = async (event) => {
    setQuotationStatus(event.target.value);
    setIsLoading(true);
    revision.set('status', event.target.value);
    revision.set("updatedBy", Parse.User.current());
    const res = await revision.save();
    setRevision(res);
    setIsLoading(false);
    const quotationParts = revision.get('quotationParts');
    const cantileverPartsQuery = new Parse.Query(Parse.Object.extend('CantileverParts')).limit(1000);
    const palletRackingPartsQuery = new Parse.Query(Parse.Object.extend('PalletRackingParts'));
    const palletRackingPartsUsedQuery = new Parse.Query(Parse.Object.extend('PalletRackingPartsUsed'));
    const cantileverParts = await cantileverPartsQuery.find();
    const palletRackingParts = await palletRackingPartsQuery.find();
    const palletRackingUsedParts = await palletRackingPartsUsedQuery.find();

    const allPartsArray = [...cantileverParts, ...palletRackingParts, ...palletRackingUsedParts];
    if ((quotationStatus === 'open') && (quotationStatus !== 'deposit' || quotationStatus !== 'won' || quotationStatus !== 'confirmed') && (event.target.value === 'won')) {
      
      quotationParts.map(async (part) => {
        const selectedPart = allPartsArray.filter((item) => item.get('partID') === part.partID);
        const partToUpdate = selectedPart[0];
        if (partToUpdate){
            const partHoldStatus = partToUpdate.get('partHoldStatus') ?? {};
            const quotationNumber = revision.get('quotationNumber');
            const value = {quantity: part.quantity};
            const partHoldStatusUpdated = Object.assign(partHoldStatus, {[quotationNumber]: value});
            partToUpdate.set('partHoldStatus', partHoldStatusUpdated);
            await partToUpdate.save();
        }
      });
    }
    else if (event.target.value !== 'won' && (quotationStatus === 'won' || quotationStatus === 'confirm') && (event.target.value === 'open')) {

      quotationParts.map(async (part) => {
        const selectedPart = allPartsArray.filter((item) => item.get('partID') === part.partID);
        const partToUpdate = selectedPart[0];
        if (partToUpdate){
            const partHoldStatus = partToUpdate.get('partHoldStatus') ?? {};
            const quotationNumber = revision.get('quotationNumber');
            delete partHoldStatus[quotationNumber];
            partToUpdate.set('partHoldStatus', partHoldStatus);
            await partToUpdate.save();
        }
      });
    }
    else if ((event.target.value === 'deposit')) {
      quotationParts.map(async (part) => {
        const selectedPart = allPartsArray.filter((item) => item.get('partID') === part.partID);
        const partToUpdate = selectedPart[0];
        console.log('partToUpdate: ', partToUpdate);
        if (partToUpdate){
            const partHoldStatus = partToUpdate.get('partHoldStatus') ?? {};
            const quotationNumber = revision.get('quotationNumber');
            const partQuantity = partToUpdate.get('quantity');
            partToUpdate.set('quantity', partQuantity - part.quantity);
            delete partHoldStatus[quotationNumber];
            partToUpdate.set('partHoldStatus', partHoldStatus);
            console.log('partToUpdate:122 ', partToUpdate);
            await partToUpdate.save();
        }
      });
      await Parse.Cloud.run('sendCustomEmailWithoutAttachmenets', {
        sender: Parse.User.current().get('email'),
        recepient: ['admin@storemax.com.au', 'operations@storemax.com.au'],
        emailBodyText: `Hi, \nThis email is an update to inform you that the quotation ${revision.get('quotationNumber')}'s status has been updated to state 'DEPOSIT'.\nPlease take the relevant steps to process the quotation pipeline.`,
        emailCCList: [Parse.User.current().get('email')],
      });
      const result = await Parse.Cloud.run('sendSMTPMail', {
        to: ['admin@storemax.com.au', 'operations@storemax.com.au'],
        subject: `[DEPOSIT] ${revision?.get("selectedClient").data.companyName} ${revision.get('quotationNumber')}`,
        text: `Hi, \nThis email is an update to inform you that the quotation ${revision.get('quotationNumber')}'s status has been updated to state 'DEPOSIT'.\nPlease take the relevant steps to process the quotation pipeline.`,
      });
      console.log('Email sent successfully:', result);
    }
    else if ((event.target.value === 'confirm')) {
      quotationParts.map(async (part) => {
        const selectedPart = allPartsArray.filter((item) => item.get('partID') === part.partID);
        const partToUpdate = selectedPart[0];
        if (partToUpdate){
            const partHoldStatus = partToUpdate.get('partHoldStatus') ?? {};
            const quotationNumber = revision.get('quotationNumber');
            const partQuantity = partToUpdate.get('quantity');
            partToUpdate.set('quantity', partQuantity - part.quantity);
            delete partHoldStatus[quotationNumber];
            partToUpdate.set('partHoldStatus', partHoldStatus);
            await partToUpdate.save();
        }
      });
    }
    else if (quotationStatus === 'deposit' && event.target.value === 'cancelled') {
      quotationParts.map(async (part) => {
        const selectedPart = allPartsArray.filter((item) => item.get('partID') === part.partID);
        const partToUpdate = selectedPart[0];
        if (partToUpdate){
            const partHoldStatus = partToUpdate.get('partHoldStatus') ?? {};
            const quotationNumber = revision.get('quotationNumber');
            const partQuantity = partToUpdate.get('quantity');
            partToUpdate.set('quantity', partQuantity + part.quantity);
            delete partHoldStatus[quotationNumber];
            partToUpdate.set('partHoldStatus', partHoldStatus);
            await partToUpdate.save();
        }
      });
    } else if (quotationStatus === 'won' && event.target.value === 'cancelled') {
      quotationParts.map(async (part) => {
        const selectedPart = allPartsArray.filter((item) => item.get('partID') === part.partID);
        const partToUpdate = selectedPart[0];
        if (partToUpdate){
            const partHoldStatus = partToUpdate.get('partHoldStatus') ?? {};
            const quotationNumber = revision.get('quotationNumber');
            partToUpdate.set('quantity', partToUpdate.quantity + partHoldStatus[quotationNumber].quantity);
            delete partHoldStatus[quotationNumber];
            partToUpdate.set('partHoldStatus', partHoldStatus);
            await partToUpdate.save();
        }
      });
    }

  };
  
  const unfinalizeRevision = async () => {
    revision.set('finalized', false);
    const res = await revision.save();
    setRevision(res);
    setIsLoading(true);
    window.location.reload();
  }

  return (
    <div>
      <Accordion key={revision.id}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box display='flex' alignItems={'center'}>
              {revision.get('finalized') && <Icon sx={{ display: 'flex', alignItems: 'center' }} color={'success'}><VerifiedIcon /></Icon>}
              <Typography color={revision.get('finalized') && colors.greenAccent[400]}>{revision?.get("quotationNumber")}{quotationStatus === "in-process" && ' | In-Process'}</Typography>
            </Box>
            <Typography color={revision.get('finalized') && colors.greenAccent[400]}>{revision.get("createdAt").toUTCString()}</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <>
            <Box
              display='flex'
              justifyContent='flex-end'
              alignItems='baseline'
            >

              <FormControl sx={{ margin: '0px 1em' }} size="small">
                {revision.get('finalized') && <Select
                  value={quotationStatus || ''} onChange={handleQuotationStatusChange}
                >
                  {Parse.User.current().get('email').includes('operations') ? quotationStatusesOperations.map((status, index) => (
                    <MenuItem key={index} value={status}>{status}</MenuItem>
                  )) : quotationStatuses.map((status, index) => (
                    <MenuItem key={index} value={status}>{status}</MenuItem>
                  ))}
                </Select>}
              </FormControl>

              <Picklist
                quotationItem={revision}
                disableDownloadButton={quotationStatus !== "deposit"}
                quotationParts = {revision.get('quotationParts')}
                quotationSections = {revision.get('state')}
                finalized={revision.get('finalized')}
                revision={revision}
              />

                {!revision.get('finalized') && (quotationStatus === "open" ||  quotationStatus === "in-process") && <Button
                  sx={{
                    backgroundColor: colors.greenAccent[700],
                    color: colors.grey[100],
                    fontSize: "14px",
                    fontWeight: "bold",
                    padding: "5px 20px",
                    mt: "1em",
                    mr: 2
                  }}
                  disabled={isLoading}
                  onClick={(e) => {setIsLoading(true); finalizeRevision(revision.id)}}
              >Finalize Revision</Button>}

              {revision.get('finalized') && <Button
                sx={{
                  backgroundColor: colors.greenAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "5px 20px",
                  mt: "1em",
                  mr: 2
                }}
                disabled={isLoading || quotationStatus !== "open"}
                onClick={(e) => unfinalizeRevision()}
              >Unfinalize</Button>}

                <DownloadQuotation
                  quotationItem={revision}
                  quotationParts={revision.get('quotationParts')}
                  quotationSections={revision.get('state')}
                  totalPriceRelativeToSection={revision?.get('marginEnabled') ? totalMarginalizedPriceRelativeToSectionQuotation : revision?.get('discountEnabled') ? totalDiscountedPriceRelativeToSectionQuotation : totalPriceRelativeToSection}
                  miscCantileverItems={revision.get('miscParts')}
                />
            </Box>
            <Box
              gridColumn="span 12"
              gridRow="span 6"
              p={"30px"}
            >
              <Box display="flex">
                {Array.apply(null, {
                  length: revision.get("state")[0].sectionCount,
                }).map((section, index) => (
                  <Tab
                    enableDeletion={false}
                    key={index}
                    label={`Section ${index + 1}`}
                    active={activeSection === index + 1}
                    onClick={() => handleTabClick(index + 1)}
                  />
                ))}
              </Box>

              <Box display="flex" alignItems="center" mt="25px" mx="1em">
                <Typography variant="h6" fontWeight="400">
                  For
                </Typography>
                <Typography
                  ml={1}
                  variant="h5"
                  fontWeight="700"
                  style={{ textDecoration: "underline" }}
                >
                  {revision?.get("client")?.get("firstName")}{" "}
                  {revision?.get("client")?.get("lastName")}
                </Typography>
                <Typography ml={2}>{revision?.get("client")?.get("email")}</Typography>
              </Box>

              {revision?.get("quotationParts")?.map((part, index) => (
                <Box key={index}>
                  {part.section === activeSection && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      mt="25px"
                      mx="1em"
                    >
                      {(part?.width || part?.height) ? <Typography variant="h6" fontWeight="400">
                        {part.name} - {part?.finish} - {part?.width} wide -{" "}
                        {part?.height} high - {part?.depth} deep
                      </Typography> : <Typography variant="h6" fontWeight="400">
                        {part.name} {part.description}
                      </Typography>}
                      <Typography variant="h6" fontWeight="400">
                        {part.quantity}
                      </Typography>
                    </Box>
                  )}
                </Box>
              ))}

              {revision.get("miscParts")?.length > 0 && (
                <Typography
                  mx={"0.5em"}
                  variant="h6"
                  fontWeight="700"
                  my="1em"
                  style={{ textDecoration: "underline" }}
                >
                  Miscellaneous Parts:
                </Typography>
              )}
              {revision.get("miscParts")?.length > 0 &&
                revision.get("miscParts")?.map((part, index) => {
                  return (
                    <Box key={index}>
                      {part.section === activeSection && (
                        <Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            mx="1em"
                          >
                            <Typography variant="h6" fontWeight="400">
                              {part?.part.name} -{" "}
                              {part?.part.dimensions
                                ? part?.part.dimensions
                                : null}{" "}
                              - {part?.part.duty ? part?.part.duty : null} -{" "}
                              {part?.part.finish?.length > 0
                                ? part?.part.finish
                                : null}
                            </Typography>
                            <Typography variant="h6" fontWeight="400">
                              {part?.part.quantity}
                            </Typography>
                          </Box>
                          <Box>
                            {part.part?.comments?.length > 0 && (
                              <Box mx="1em">
                                <Box
                                  style={{
                                    marginTop: "3px",
                                  }}
                                >
                                  {part.part.comments}
                                </Box>
                              </Box>
                            )}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  );
                })}

              {(revision?.get('marginEnabled') || revision?.get('discountEnabled')) && <Box>
                <Typography mx={'0.5em'} variant="h6" fontWeight="700" my='1em' color={colors.greenAccent[500]}>{(revision?.get('marginEnabled') !== undefined && revision?.get('marginEnabled')[activeSection] && revisionSections[activeSection - 1]?.marginPercentage) ? `Margin applied - ${revisionSections[activeSection - 1]?.marginPercentage}%` : (revision?.get('discountEnabled')[activeSection] && revision?.get('discountEnabled')[activeSection] && revisionSections[activeSection - 1]?.discountPercentage) ? `Discount Applied - ${revisionSections[activeSection - 1]?.discountPercentage}%` : null}</Typography>
              </Box>}
              <Box
                mt="25px"
                mx="1em"
                border="1px solid lightgray"
                p="1em"
                borderRadius="4px"
              >
                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h6" fontWeight="400">
                    Total Amount
                  </Typography>
                  <Typography variant="h6" fontWeight="400">
                    $
                    {overallTotal.toFixed(2)}
                  </Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h6" fontWeight="400">
                    GST
                  </Typography>
                  <Typography variant="h6" fontWeight="400">
                    ${(overallTotal / 100 * 10).toFixed(2)}
                  </Typography>
                </Box>

                <Box display="flex" justifyContent="space-between">
                  <Typography variant="h6" fontWeight="400">
                    Net Amount
                  </Typography>
                  <Typography variant="h6" fontWeight="400">
                    $
                    {(overallTotal + (overallTotal / 100 * 10)).toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
