import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function BasicDatePicker(props) {

    const date = props.date;
    const setDate = props.setDate;
    const label = props.label

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker 
            label={label}
            value={date}
            onChange={setDate}
            format="DD/MM/YYYY"  // This line sets the date format
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}