import { pdf, Document, Page, Text, View, StyleSheet, Image, Font } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { BRACE_NAME } from "./QuotationCartItem";
import storemaxLogo from '../assets/images/storemax.png';

import { Button, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { COLUMN_NAME } from "./CantileverPart";
import { ARM_NAME } from "./CantileverPart";
import { BASE_NAME } from "./CantileverPart";

import notoSansSrc from '../assets/fontFamilies/Noto_Sans/NotoSans-Regular.ttf';
import notoSansSrcBold from '../assets/fontFamilies/Noto_Sans/NotoSans-Bold.ttf';
import notoSansSrcBoldSemi from '../assets/fontFamilies/Noto_Sans/NotoSans-SemiBold.ttf';
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { quotationTypesShort } from "../scenes/calendar/calendar";
import { calculateSectionTotal } from "./utils";

Font.register({ family: 'NOTO', src: notoSansSrc, fontWeight: 600 });
Font.register({ family: 'NOTO-BOLD', src: notoSansSrcBold });
Font.register({ family: 'NOTO-SEMI-BOLD', src: notoSansSrcBoldSemi });

export const styles = StyleSheet.create({
	page: {
		backgroundColor: "white",
		fontFamily: 'NOTO'
	},
	header: {
		marginVertical: "1%",
	},
	companyInfo: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: 'center',
		marginBottom: '18'
	},
	clientInfoAndTotal: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: 'center',
		marginBottom: '8',
		marginLeft: '4'
	},
	totalContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		height: '80px',
		width: '250px',
		padding: '6'
	},
	clientInfo: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		marginTop: '10px',
	},
	container: {
		padding: 30,
		flexGrow: 1,
	},
	rowHeader: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		backgroundColor: "#E3E3E3",
		padding: '6px',
		borderRadius: '4px'
	},
	row: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: '5px'
	},
	rowTerms: {
		flex: 1,
		flexDirection: 'row'
	},
	left: {
		padding: 20,
		width: '50%',//<- working alternative
	},

	right: {
		padding: 20,
		width: '50%', //<- working alternative
	},
	itemRow: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		marginVertical: '2px',
		alignItems: 'center'
	},
	containerBillInfo: {
		width: '100%',
		borderTop: '1px solid lightgrey',
	},
	imgContainer: {
		padding: '4px',
		border: '1px solid #E3E3E3',
		borderRadius: '3px',
		marginRight: '4'
	},
	totalBillInfo: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		marginVertical: '5px'
	},
	itemText: {
		fontSize: '10px'
	},
	quantityText: {
		fontSize: '10px',
		marginRight: '10px',
		minWidth: '15px',
		textAlign: 'right'
	},
	miscParts: {
		fontSize: '10px',
		marginVertical: '6px',
		color: '#0E68A0'
	},
	commentsStyle: {
		width: "100%",
		marginTop: '3px'
	},
	totalSectionPriceText: {
		fontSize: '9px',
		marginRight: '35px',
		fontWeight: '800',
		textDecoration: 'underline'
	},
	totalPriceText: {
		fontSize: '10px',
		marginRight: '40px',
		fontWeight: '800'
	},
	totalPriceValue: {
		fontSize: '10px',
	},
	totalSectionPriceValue: {
		fontSize: '10px',
		textDecoration: 'underline'
	},
	netPriceValue: {
		fontSize: '10px',
		padding: '2',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		marginVertical: '5px'
	},
	rowBetween: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: "space-between",
	},
	termsTextSize: {
		fontSize: '9px'
	},
	termsHeading: {
		fontSize: '9px',
		fontFamily: 'NOTO-BOLD'
	},
	termsSemiHeading: {
		fontFamily: 'NOTO-SEMI-BOLD',
		fontSize: '9px'
	}
});

export default function DownloadQuotationBeforeSending(props) {
	const [isLoading, setIsLoading] = useState(false);
	const salesRep = props.salesRep;
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
	const client = props.client;
	const sections = props.sections;
	const downloadQuotationPDFSavesQuotation = props.downloadQuotationPDFSavesQuotation;
	const quotationTerms = props.quotationTerms;
	const sectionTotalsEnabled = props.sectionTotalsEnabled;
	const quotationNumber = props.quotationNumber;
	const quotationParts = props.quotationParts;
	const quotationType = props.quotationType;
	const overallTotal = props.overallTotal;
	const quotationSections = props.quotationSections;
	const miscCantileverItems = props.miscCantileverItems;
	const currentDate = new Date();
	const formattedDate = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(currentDate);
	const [sectionRelativeTypes, setSectionRelativeTypes] = useState({});

	useEffect(() => {
		if (quotationParts) {
			const sectionTypes = {};
			quotationParts.map((item) => {
				if (!sectionTypes[item.section]) {
					return sectionTypes[item.section] = item.part.className === "CantileverParts" ? "Cantilever Racking" : "Pallet Racking"
				}
			});
			setSectionRelativeTypes(sectionTypes)
		}
	}, [quotationParts])

	const MyDocument = () => (
		<Document>
			<Page size="a4" style={styles.page}>
				<View style={styles.container}>
					<View style={styles.header}>
						<View style={styles.companyInfo}>
							<View>
								<Image
									style={{
										width: "180px",
										marginLeft: "-20",
										marginTop: "-50px",
									}}
									src={storemaxLogo}
								/>
							</View>
							<View>
								<Text
									style={{
										fontSize: "10px",
										marginVertical: "2",
										fontFamily: 'NOTO-SEMI-BOLD'
									}}
								>
									STOREMAX AUSTRALIA PTY. LTD.
								</Text>
								<Text style={{ fontSize: "8px", marginVertical: "2", fontFamily: 'NOTO-SEMI-BOLD', marginBottom: '5' }}>
									ABN: 42 162 922 107
								</Text>
								<Text
									style={{
										fontSize: "7px",
										marginVertical: "1",
									}}
								>
									Head Office - 1/25 Infinity Drive
								</Text>
								<Text
									style={{
										fontSize: "7px",
										marginVertical: "1",
									}}
								>
									Truganina VIC- 3029
								</Text>
								<Text
									style={{
										fontSize: "7px",
										marginVertical: "1",
									}}
								>
									Ph: 1300 83 24 26
								</Text>
								<Text
									style={{
										fontSize: "7px",
										marginVertical: "1",
									}}
								>
									Email: sales@storemax.com.au
								</Text>
								<Text
									style={{
										fontSize: "10px",
										marginTop: "10",
										fontFamily: 'NOTO-BOLD'
									}}
								>
									QUOTE: SMAX-{quotationNumber}
								</Text>
								<Text
									style={{
										fontSize: "9px",
									}}
								>
									Date: {formattedDate}
								</Text>
							</View>
						</View>

						<View style={styles.clientInfoAndTotal}>
							<View>
								<Text
									style={{
										fontSize: "9px",
										fontWeight: "900",
										marginBottom: "6",
										fontFamily: 'NOTO-SEMI-BOLD'
									}}
								>
									Quotation For
								</Text>
								<Text style={{ fontSize: "9px", fontWeight: "100" }}>
									{client.get("companyName")}
								</Text>
								<Text style={{ fontSize: "9px", fontWeight: "100" }}>
									{client.get("firstName")} {client.get("lastName")}
								</Text>
								<Text style={{ fontSize: "9px", fontWeight: "100" }}>
									{client.get("address")}
								</Text>
								<Text style={{ fontSize: "9px", fontWeight: "100" }}>
									{client.get("email")}
								</Text>
							</View>
							<View>
								<Text
									style={{
										fontSize: "9px",
										fontWeight: "900",
										marginBottom: "6",
										fontFamily: 'NOTO-SEMI-BOLD'
									}}
								>
									Sales Representative
								</Text>
								<Text style={{ fontSize: "9px", fontWeight: "100" }}>
									{salesRep.get("username")}
								</Text>
								<Text style={{ fontSize: "9px", fontWeight: "100" }}>
									{salesRep.get("email")}
								</Text>
							</View>
						</View>

					</View>

					<View style={styles.rowHeader}>
						<Text style={styles.itemText}>Item</Text>
						<View style={styles.rowBetween}>
							<Text style={{ fontSize: "10px", marginRight: "30px" }}>
								Finish
							</Text>
							<Text style={styles.itemText}>Quantity</Text>
						</View>
					</View>

					{Array.apply(null, { length: quotationSections }).map((section, sectionIndex) => (
						<View key={sectionIndex}>
							<Text style={{ fontSize: "10px", marginTop: "5px", border: '1px solid gray', borderRadius: '4px', padding: 3 }} break>
								Section - {sectionIndex + 1} {sectionRelativeTypes[sectionIndex + 1]}
							</Text>
							{quotationParts.map((part, index) => (
								<View key={index} break>
									{part.part?.get("name") &&
										part.section === sectionIndex + 1 && (
											<View key={index} style={styles.itemRow}>
												<View
													style={{
														display: "flex",
														flexDirection: "row",
														alignItems: "center",
													}}
												>
													{part.part?.className === 'CantileverParts' && part.part?.get('name') === COLUMN_NAME ?
														<Text style={styles.itemText}>
															Column {part.part.get("height") + " High /" + part.part.get("width")}
														</Text> : part.part?.className === 'CantileverParts' && part.part?.get('name') === BASE_NAME ?
															<Text style={styles.itemText}>
																Base {part.part.get("sides") === "1" ? 'S/S for ' : 'D/S for '}{part?.part?.get("armToSuitBase")}{" Arms /"}{(part?.part?.get("duty") === 'Light' ? '200' : part?.part?.get("duty") === 'Medium' ? '305' : part?.part?.get("duty") === 'Heavy' ? '350' : null)} ({(part?.part?.get("width"))})
															</Text> : part.part?.className === 'CantileverParts' && part.part?.get('name') === ARM_NAME ?
																<Text style={styles.itemText}>
																	Arm H{part.part.get("height")} {part.part.get("depth")}
																</Text> : part.part?.className === 'CantileverParts' && part.part?.get('name') === BRACE_NAME ?
																	<Text style={styles.itemText}>
																		{part.part.get("bayCenter").split(" ")[0] === "Horizontal" ? "H" : 'D'} Bracing {part.part.get("bayCenter").split(" ")[1]} c/c ({part.part.get("width")})
																	</Text>
																	: <Text style={styles.itemText}>
																		{part.part?.get("name")}{" "}{part.part?.get("description")}{`/ ${part.part?.get('brand') ?? ""}`}{" "}{(part?.part.get('name') === "Beam" || part?.part.get('name') === "Frame") && part?.part.get('condition') === 'new' ? "(New)" : part?.part.get('condition') === 'Used' ? "(Used)" : null }
																	</Text>}
												</View>
												<View style={styles.rowBetween}>
													<Text
														style={{ fontSize: "10px", marginRight: "50px" }}
													>
														{part.part.get("finish") === "Blue"
															? "Powder Coated"
															: part.part.get("finish") === "Galv" ? 'Galvanized' : part.part.get("finish")}
													</Text>
													<Text style={styles.quantityText}>
														{part.quantity}
													</Text>
												</View>
											</View>
										)}
								</View>
							))}

							{/* MISC PARTS */}

							{miscCantileverItems?.some((item) => item.section === sectionIndex + 1) && (
								<Text style={styles.miscParts}>Miscellaneous Parts</Text>
							)}

							{miscCantileverItems.map((part, index) => (
								<View key={index}>
									{part.part?.name && part.section === sectionIndex + 1 && (
										<>
											<View style={styles.itemRow}>
												<Text style={styles.itemText}>
													{part.part?.name} -{" "}
													{part.part?.dimensions.length > 0
														? part.part?.dimensions
														: null}{" "}
													-{" "}
													{part.part?.duty.length > 0 ? part.part?.duty : null}{" "}
													-{" "}
													{part.part?.finish.length > 0
														? part.part?.finish
														: null}
												</Text>
												<Text style={styles.quantityText}>{part.quantity}</Text>
											</View>

											{part.part.comments.length > 0 && (
												<View key={index}>
													<View style={styles.commentsStyle}>
														<Text style={styles.quantityText}>
															{part.part.comments}
														</Text>
													</View>
												</View>
											)}
										</>
									)}
								</View>
							))}

							{sectionTotalsEnabled && <View style={styles.totalBillInfo}>
								<Text style={styles.totalSectionPriceText}>Section Total Amount</Text>
								<Text style={styles.totalSectionPriceValue}>
									{/* ${totalPriceRelativeToSection[sectionIndex + 1].toFixed(2)} */}
									${(calculateSectionTotal(sections[sectionIndex]?.products, sections[sectionIndex]?.marginPercentage, sections[sectionIndex]?.discountPercentage)).toFixed(2)
									}
								</Text>
							</View>}

						</View>
					))}
					<View style={{ marginTop: "10" }} break>
						<View style={styles.containerBillInfo}>
							<View style={styles.totalBillInfo}>
								<Text style={styles.totalPriceText}>Total Amount</Text>
								<Text style={styles.totalPriceValue}>
									${overallTotal.toFixed(2)}
								</Text>
							</View>
							<View style={styles.totalBillInfo}>
								<Text style={styles.totalPriceText}>GST</Text>
								<Text style={styles.totalPriceValue}>
									${(overallTotal/100 * 10).toFixed(2)}
								</Text>
							</View>
							<View style={styles.netPriceValue}>
								<View
									style={{
										backgroundColor: "#E3E3E3",
										display: "flex",
										flexDirection: "row",
										padding: "4",
									}}
								>
									{/* make this bold */}
									<Text style={styles.totalPriceText}>Net Amount</Text>
									<Text style={styles.totalPriceValue}>
										${(overallTotal + (overallTotal/100 * 10)).toFixed(2)}
									</Text>
								</View>
							</View>
						</View>
					</View>
				</View>
			</Page>



			{/* terms and conditions */}

			<Page size="a4" style={styles.page}>
				<View style={[styles.rowTerms]}>
					<View style={styles.left}>
						<View>
							<Text
								style={[
									styles.termsHeading,
									{ textAlign: "center", marginBottom: "10" },
								]}
							>
								TERMS
							</Text>
							<Text style={styles.termsTextSize}>
								• {quotationTerms[1]}
							</Text>
							<Text style={styles.termsTextSize}>
								• {quotationTerms[2]}
							</Text>
							<Text style={styles.termsTextSize}>
								• {quotationTerms[3]}
							</Text>
							<Text style={styles.termsTextSize}>
								• {quotationTerms[4]}
							</Text>
							<Text style={styles.termsTextSize}>
								• All systems must be installed on a level concrete surface
							</Text>
							<Text style={styles.termsTextSize}>
								• Quoted Price does not include levelling of uneven surface
								(other than that which is generally applied with
								packing/levelling shims)
							</Text>
							<Text
								style={[
									styles.termsHeading,
									{ textAlign: "center", marginBottom: "10" },
								]}
							>
								General Terms &amp; Conditions
							</Text>
							<Text style={styles.termsTextSize}>
								These Terms and Conditions, along with any schedules, referred
								to as &quot;these Terms,&quot; apply to all Orders made by the
								Customer and are binding on both the Customer and STOREMAX.
								These Terms will be included in every contract between the
								Customer and STOREMAX unless otherwise agreed to in writing by
								STOREMAX.
							</Text>
						</View>

						<View style={{ padding: "5" }}>
							<Text style={[styles.termsSemiHeading, { marginBottom: "5" }]} break>
								1.			Orders
							</Text>
							<Text style={[styles.termsTextSize, { marginBottom: "5" }]} break>
								1.1					STOREMAX reserves the right to accept or reject any order.
								Any quotation provided by STOREMAX is subject to change or
								cancellation at any time before STOREMAX accepts an order
								related to that quotation. This may be due to factors such as
								changes in exchange rates or limited stock availability. Unless
								otherwise stated in writing, all quotations expire after a
								period of Seven (7) days from the date of the quotation.
							</Text>
							<Text style={[styles.termsTextSize, { marginBottom: "5" }]} break>
								1.2					In order to accept an Order by STOREMAX, the Customer needs
								to pay a deposit. The acceptance of the Order will only occur
								once the Customer has made the deposit payment to STOREMAX. If
								the Goods are custom-made or non-standard, STOREMAX requires the
								Customer to pay the full Price upon acceptance of the Order.
							</Text>
							<Text style={[styles.termsTextSize, { marginBottom: "5" }]} break>
								1.3					For Custom Goods, it is the responsibility of the Customer
								to provide detailed drawings and specifications, or to approve
								any drawings or specifications produced by STOREMAX at the
								Customer&#39;s request. The final drawings and specifications
								must be approved by client in writing and deemed an
								&quot;Approved Design&quot; before STOREMAX can accept an Order
								to supply Goods based on those drawings and specifications.
							</Text>
							<Text
								style={[styles.termsTextSize, { marginBottom: "10" }]}
								break
							>
								1.4					By providing an Approved Design, the Customer warrants that
								it does not violate any intellectual property rights of a third
								party. The Customer also agrees to protect STOREMAX against any
								damages, penalties, costs, and expenses that may arise as a
								result of any claim by a third-party alleging infringement of a
								patent, trademark, registered design, copyright, or any other
								common law right related to any work carried out by STOREMAX in
								accordance with the Approved Design provided by the Customer.
							</Text>
						</View>

						<View style={{ padding: "5" }}>
							<Text style={[styles.termsSemiHeading, { marginBottom: "5" }]} break>
								2.					Price and Payment
							</Text>
							<Text
								style={[styles.termsTextSize, { marginBottom: "10" }]}
								break
							>
								2.1					The Price for the Goods and/or Services will be either the
								amount specified in the written quotation provided by STOREMAX
								to the Customer or, in the absence of a written quotation, the
								Price communicated to the Customer by STOREMAX.
							</Text>
						</View>


						{/* NEXT PAGE */}


						<View style={{ marginLeft: '5', padding: '10' }}>
							<Text style={[styles.termsTextSize, { marginBottom: "5", marginTop: '10' }]} break>
								a.					the Goods must be insured for their full insurable or replacement value, whichever is
								higher.
							</Text>
							<Text style={[styles.termsTextSize, { marginBottom: "5" }]} break>
								b.					The Goods must be insured by an insurer licensed or authorized to conduct the
								business of insurance in the place where the Goods will be stored.
							</Text>
						</View>

						<Text style={[styles.termsTextSize, { marginBottom: "5" }]}>
							4.4					The Customer agrees that they will not be released from their obligation to pay STOREMAX any
							outstanding amounts until all money due and owing to STOREMAX has been paid.
						</Text>
						<View style={{ marginLeft: '5' }}>
							<Text style={[styles.termsTextSize, { marginBottom: "5" }]} break>
								(a)					The Customer is required to hold and keep all Goods provided by STOREMAX as a fiduciary
								and trustee for STOREMAX. Additionally, the Customer must store the Goods in a manner
								that clearly indicates that STOREMAX is the rightful owner of the Goods
							</Text>
							<Text style={[styles.termsTextSize, { marginBottom: "5" }]} break>
								(b)					The Customer is only allowed to sell the Goods provided by STOREMAX in the normal course
								of their business, but must do so under the condition that they hold all proceeds in trust for
								STOREMAX.
							</Text>

						</View>


						<View>
							<Text style={[styles.termsSemiHeading, { marginBottom: "5" }]} break>
								5.					Default by Customer
							</Text>
							<Text
								style={[styles.termsTextSize, { marginBottom: "5" }]}
								break
							>
								5.1					If the Customer violates a contract for the provision of Goods or Services and does not rectify the
								default within 7 days of receiving written notice, or if the Customer bounces a cheque or fails to
								make payment for any Invoice, STOREMAX may choose to demand the immediate payment in full of
								all outstanding amounts owed by the Customer to STOREMAX, upon giving written notice to the
								Customer of STOREMAX&#39;s intention to exercise this option..
							</Text>
							<Text
								style={[styles.termsTextSize, { marginBottom: "5" }]}
								break
							>
								5.2					STOREMAX is not obligated to deliver any Goods or provide any services under an Order until
								STOREMAX has accepted it and, if required, until the Customer has paid any deposit and all amounts
								owed to STOREMAX for the supply contracts or invoices.
							</Text>
							<Text
								style={[styles.termsTextSize, { marginBottom: "5" }]}
								break
							>
								5.3					If not otherwise agreed upon, if the Customer does not make payment in accordance with
								STOREMAX&#39;s terms, STOREMAX may charge and receive interest on the outstanding balance from
								the date of the invoice until the date of payment. The interest rate will be calculated using the
								indicative &quot;cash&quot; rate of the Reserve Bank of Australia as published in the Australian Financial
								Review (&quot;AFR&quot;), or if not published in the AFR, the equivalent rate advised by Reuters Information
								Services plus a margin of 5% per annum.
							</Text>
							<Text
								style={[styles.termsTextSize, { marginBottom: "5" }]}
								break
							>
								5.4					If the Customer defaults on any payment owed to STOREMAX, then in addition to STOREMAX&#39;s
								rights under these Terms and under the law, the Customer will not be entitled to a refund and will
								be required to pay STOREMAX all costs and expenses incurred in recovering the money owed by the
								Customer and/or recovering any Goods that are in the possession of the Customer or a third party.
								These costs and expenses will be on a solicitor own client basis.
							</Text>
						</View>

						<View>
							<Text style={[styles.termsSemiHeading, { marginBottom: '5' }]} break>
								6.					Liability
							</Text>
							<Text
								style={[styles.termsTextSize, { marginBottom: "5" }]}
								break
							>
								6.1					The Customer waives any claims it may have against STOREMAX in contract, tort, under statute,
								or otherwise, to the extent permitted by law, for any loss, costs, injury, harm, or damage suffered or
								incurred by the Customer or any other person arising from or in connection with the supply of Goods
								and/or provision of services (including installation services) by STOREMAX. This includes any direct,
								special, indirect, consequential, or other types of losses, such as loss of profit or any other economic
								loss.
							</Text>
						</View>

						<View>
							<Text
								style={[styles.termsTextSize, { marginTop: '10' }]}
								break
							>
								10.2					To maintain the benefits of the Defects Warranty, the Customer must adhere to the following
								conditions:
							</Text>
							<Text
								style={[styles.termsTextSize, { marginBottom: "5" }]}
								break
							>
								10.3					Throughout the Warranty Period, it is mandatory for the Customer to permit STOREMAX or an
								authorized representative of STOREMAX to perform an annual inspection of any STOREMAX
								racking that has been supplied and installed. The responsibility for scheduling the annual
								inspections to uphold this warranty lies with the Customer, who must contact STOREMAX
								offices.
							</Text>
							<Text
								style={[styles.termsTextSize, { marginBottom: "5" }]}
								break
							>
								10.4					The Customer is required to request an inspection by STOREMAX under clause 10.3 within
								every 12-month period from the date of purchase. STOREMAX will conduct an inspection of the site
								as soon as possible thereafter. The Customer will be billed for inspections at the prevailing standard
								rate established by STOREMAX. If the Customer fails to comply with this requirement, the
								Customer&#39;s entitlement to make a warranty claim may be affected.
							</Text>
							<View style={{ marginLeft: '5' }}>
								<Text
									style={[styles.termsTextSize, { marginBottom: "5" }]}
									break
								>
									(a)					Make a request as aforesaid;
								</Text>
								<Text
									style={[styles.termsTextSize, { marginBottom: "5" }]}
									break
								>
									(b)					Grant STOREMAX permission to conduct an inspection.
								</Text>
								<Text
									style={[styles.termsTextSize, { marginBottom: "5" }]}
									break
								>
									(c)					If the Customer pays for an inspection conducted by STOREMAX, this may result in the voiding of
									the warranty.
								</Text>
							</View>
						</View>

						<View>
							<Text style={[styles.termsSemiHeading, { marginBottom: '5' }]} break>
								11.					Additional Installation Terms (If Installation is Included in the quote)
							</Text>
							<View style={{ marginLeft: '5' }}>
								<Text
									style={[styles.termsTextSize, { marginBottom: "5" }]}
									break
								>
									(a)					Client to supply Fork Lift &amp; Scissor lift for the duration of installation or by Storemax only if
									communicated by STOREMAX to the Customer
								</Text>
								<Text
									style={[styles.termsTextSize, { marginBottom: "5" }]}
									break
								>
									(b)					Installers to provide all required tools and equipment
								</Text>
								<Text
									style={[styles.termsTextSize, { marginBottom: "5" }]}
									break
								>
									(c) 				Client to provide uninterrupted use of forklift for duration of installation project
								</Text>
								<Text
									style={[styles.termsTextSize, { marginBottom: "5" }]}
									break
								>
									(d)					Client to provide uninterrupted access to site throughout duration of installation
								</Text>
								<Text
									style={[styles.termsTextSize, { marginBottom: "5" }]}
									break
								>
									(e)					Installation Team requires full access to uninterrupted power supply – including adequate
									240V AC power outlets
								</Text>
								<Text
									style={[styles.termsTextSize, { marginBottom: "5" }]}
									break
								>
									(f)					Goods must be placed within the installation area – or an easily accessible area
								</Text>
								<Text
									style={[styles.termsTextSize, { marginBottom: "5" }]}
									break
								>
									(g)					Installation includes unloading of trucks/containers (client will provide uninterrupted access)
								</Text>

								<Text
									style={[styles.termsTextSize, { marginBottom: "5" }]}
									break
								>
									(h)					Any additional man-hours incurred as a result of the client’s inability to adhere to the above
									terms will be chargeable to the client
								</Text>

								<Text
									style={[styles.termsTextSize, { marginBottom: "5" }]}
									break
								>
									(i)					Balance payment and delivery due upon goods arrival to port/warehouse. Client’s inability to
									accept goods on contracted/planned date may result in charges being billing to client on a pro-
									rate basis. Any delays to site access/install start should be notified to Storemax immediately in
									order to address any possible incurred costs.
								</Text>
							</View>
						</View>


					</View>


					{/* RIGHT SIDE */}



					<View style={styles.right}>

						<View style={{ padding: "5" }}>
							<Text style={[styles.termsSemiHeading, { marginBottom: "5" }]} break>
								3.					Delivery
							</Text>
							<Text style={[styles.termsTextSize, { marginBottom: "5" }]} break>
								3.1					If STOREMAX is responsible for delivering the Goods to the
								Customer, as stated on the STOREMAX invoice or agreed upon
								separately, the following conditions will apply:
							</Text>
							<View style={{ marginLeft: '5' }}>
								<Text style={[styles.termsTextSize, { marginBottom: "5" }]} break>
									(a)					The Customer does not assume ownership or property rights of
									the Goods until STOREMAX has received full payment from the
									Customer for all sums owed in connection with any Goods supplied
									by STOREMAX.
								</Text>
								<Text style={[styles.termsTextSize, { marginBottom: "5" }]} break>
									(b)					The Customer acknowledges and agrees to pay a reasonable
									delivery fee as determined by STOREMAX.
								</Text>
								<Text style={[styles.termsTextSize, { marginBottom: "5" }]} break>
									(c)					Although the standard delivery time is typically 10-15
									working days from the later of payment of deposit or acceptance
									of Order, any delivery times provided to the Customer are only
									estimates. STOREMAX will not be held responsible for any loss,
									damage, or delay suffered by the Customer or its clients
									resulting from late or non-delivery of the Goods.
								</Text>
								<Text style={[styles.termsTextSize, { marginBottom: "5" }]} break>
									(d)					If the Customer refuses or fails to accept delivery of the
									Goods, the Customer forfeits any right to a refund of the
									Deposit or any amounts previously paid. Additionally, STOREMAX
									reserves the right to charge a reasonable storage fee or take
									action against the Customer as allowed by these Terms or
									applicable law, including but not limited to demanding payment
									of the outstanding balance owed for the relevant Order, even if
									the Customer has declined delivery.
								</Text>
								<Text
									style={[styles.termsTextSize, { marginBottom: "10" }]}
									break
								>
									(e)					Client to supply fork lift to unload the material from
									delivery truck
								</Text>
							</View>
						</View>


						<View style={{ padding: "5" }}>
							<Text style={[styles.termsSemiHeading]} break>
								4.					Title and Risk
							</Text>
							<Text style={[styles.termsTextSize, { marginBottom: "5" }]} break>
								4.1					All Goods supplied by STOREMAX to the Customer will remain the exclusive and absolute
								property of STOREMAX until the Customer has paid all outstanding amounts owed to STOREMAX on
								any account.
							</Text>
							<Text style={[styles.termsTextSize, { marginBottom: "5" }]} break>
								4.2					STOREMAX retains the right to reclaim possession of and sell any Goods supplied by STOREMAX
								to the Customer, as STOREMAX deems necessary, at any time that monies owed but not paid by the
								Customer.
							</Text>
							<Text style={[styles.termsTextSize, { marginBottom: "5" }]} break>
								4.3					The risk associated with Goods supplied by STOREMAX to the Customer will be transferred from
								STOREMAX to the Customer:
							</Text>
							<View style={{ marginLeft: '5' }}>
								<Text style={[styles.termsTextSize, { marginBottom: "5" }]} break>
									(a)					The risk associated with Goods supplied by STOREMAX to the Customer will be transferred from
									STOREMAX to the Customer:
								</Text>
								<Text style={[styles.termsTextSize, { marginBottom: "10" }]} break>
									(b)					If the Goods are not being shipped or trucked, the risk transfers to the Customer
									immediately upon dispatch from STOREMAX premises. If the Goods are collected by the
									Customer or delivered by STOREMAX from a location other than STOREMAX premises
									(known as &quot;Departure Point&quot;), the risk in the Goods transfers to the Customer as soon as
									they are dispatched from the Departure Point. The Customer is responsible for insuring the
									Goods from the time the risk is transferred until all payments due to STOREMAX are made.
									The Goods must be insured by the Customer on the following conditions until property,
									including legal and equitable title, is transferred to the Customer:
								</Text>
							</View>

						</View>


						<View style={{ padding: "5" }}>
							<Text style={[styles.termsSemiHeading, { marginTop: '10' }]} break>
								7.					Claims
							</Text>
							<Text
								style={[styles.termsTextSize, { marginBottom: "5" }]}
								break
							>
								7.1					Upon delivery, it is the Customer&#39;s responsibility to inspect the Goods.
							</Text>
							<Text
								style={[styles.termsTextSize, { marginBottom: "5" }]}
								break
							>
								7.2					Upon delivery, it is the Customer&#39;s responsibility to inspect the Goods.
							</Text>

							<Text
								style={[styles.termsTextSize, { marginBottom: "5" }]}
								break
							>
								7.2					The Customer must notify STOREMAX of any alleged defect, error, omission, shortage in
								quantity, damage, or failure to comply with the relevant contract for supply within two (2) business
								days of the supply of any Goods and/or Services, with time being of the essence.
							</Text>

							<Text
								style={[styles.termsTextSize, { marginBottom: "5" }]}
								break
							>
								7.3					The Customer must allow STOREMAX to inspect the alleged defect, error, omission, shortage in
								quantity, damage, or failure to comply within a reasonable time after receiving notification
							</Text>

							<Text
								style={[styles.termsTextSize, { marginBottom: "10" }]}
								break
							>
								7.4					If the Customer fails to give notice in accordance with clause 7.2, STOREMAX will be considered
								to have fulfilled the contract for the supply, and the Customer will not be able to make a claim in
								respect of the Goods unless it is under a non-excludable statutory term or the Express Warranties.
							</Text>
						</View>

						<View style={{ padding: "5" }}>
							<Text style={[styles.termsSemiHeading]} break>
								8.					Force Majeure
							</Text>
							<Text
								style={[styles.termsTextSize, { marginBottom: "10" }]}
								break
							>
								8.1					If the performance or exercise of any obligation under these Terms is prevented or hindered in
								whole or in part by a Force Majeure Event, STOREMAX will not be liable for any delay or failure in
								performance of that obligation or for any loss or damage (including indirect or consequential loss or
								damage).
							</Text>
						</View>


						<View style={{ padding: "5" }}>
							<Text style={[styles.termsSemiHeading]} break>
								9.					Returns
							</Text>
							<Text
								style={[styles.termsTextSize, { marginBottom: "5" }]}
								break
							>
								9.1					Unless the Customer returns the Goods to STOREMAX or notifies STOREMAX of any claim that
								the Goods are not as per an Order, of acceptable quality, damaged, or defective within five (5) days
								of the date of delivery, the Customer will be considered to have accepted the Goods in the condition
								in which they are delivered.
							</Text>

							<Text
								style={[styles.termsTextSize, { marginBottom: "5" }]}
								break
							>
								9.2					STOREMAX reserves the right to approve any request for the return of conforming, non-
								defective Goods at its sole discretion. If STOREMAX approves such a request, it may be subject to a
								processing fee of 20% of the Price of those Goods, at the discretion of STOREMAX.
							</Text>

							<Text
								style={[styles.termsTextSize, { marginBottom: "10" }]}
								break
							>
								9.3				The Customer will be responsible for any fees associated with the transportation of returned
								Goods to STOREMAX and, if necessary, back to the Customer.
							</Text>


						</View>


						<View>
							<Text style={[styles.termsSemiHeading, { marginBottom: "5" }]} break>
								10.					Express Warranty against Defects in Materials and Workmanship
							</Text>
							<Text
								style={[styles.termsTextSize, { marginBottom: "5" }]}
								break
							>
								10.1					STOREMAX provides a Defects Warranty for all new and previously unused products and
								equipment bought by the Customer from them and installed by registered trade installation crews
								approved by STOREMAX. Under this warranty, the products and equipment are guaranteed to be
								free from defects in materials and workmanship for a period of 1 years from the date of purchase,
								with the possibility of early termination or extension as outlined below (the Warranty Period).
							</Text>
						</View>


					</View>
				</View>
			</Page>
		</Document>
	);

	const sanitizeFilename = (filename) => {
		// Remove any characters that are not letters, numbers, spaces, dashes, or underscores
		return filename.replace(/[^a-zA-Z0-9 \-_]/g, '');
	};

	const saveFile = async () => {
		// This does the trick!
		setIsLoading(true);
		let types = '';
		quotationType.map((type, index) => {
			if (index === 0) {
				types = quotationTypesShort[type];
			} else if (index > 0 && index < quotationType.length) {
				types += "-" + quotationTypesShort[type];
			}
		});

		setIsLoading(true);
		await downloadQuotationPDFSavesQuotation();
		pdf(<MyDocument />)
			.toBlob()
			.then((blob) => {
				setIsLoading(false);
				// Construct the filename and sanitize it
				let filename = client.get('companyName') + '-' + types + '-quote-' + quotationNumber;
				filename = sanitizeFilename(filename);
				saveAs(blob, filename);
			});
	};

	return (
		<Button
			sx={{
				backgroundColor: colors.blueAccent[700],
				color: colors.grey[100],
				fontSize: "14px",
				fontWeight: "bold",
				padding: "5px 20px",
				mt: '1em'
			}}
			disabled={isLoading || (quotationParts.length === 0 && miscCantileverItems.length === 0) || !client || quotationType.length === 0}
			onClick={saveFile}
		>
			{!isLoading ? 'Download Quotation' : <Spinner animation="border" role="status" size="sm" variant="primary" />}
		</Button>
	);
}
