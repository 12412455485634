import { Box, IconButton, useTheme } from "@mui/material";
import cogsIcon from '../assets/images/adjust.png'
import { useDispatch } from "react-redux";
import { deleteMiscCartItem } from "../features/cart/cartSlice";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { tokens } from "../theme";

const MiscCartItem = (props) => {
    const part = props.part;
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const handleDelete = () => {
      dispatch((deleteMiscCartItem(part)))
    }

    return (
        <Box width="100%">
          <Box display="flex" alignItems="center" mx="0.5em">
            <div className="pr-4 mb-3">
              <img
                alt="profile-user"
                width="50px"
                height="50px"
                style={{ cursor: "pointer", borderRadius: "50%" }}
                src={cogsIcon}
              ></img>
            </div>
            <div className="d-flex flex-column flex-start">
              <div className="d-flex">
                <p className="q-color">{part.part.name}</p>
              </div>
              <ul className="p-0">
                <li>{part.part.dimensions}</li>
              </ul>
            </div>
        </Box>
          {part.part.finish.length > 0 && <div className="d-flex justify-content-between align-items-baseline px-2">
            <strong className="q-color">Finish:</strong>
            <p className="q-color">{part.part.finish}</p>
          </div>}
          <div className="d-flex justify-content-between align-items-baseline px-2">
            <strong className="q-color">Price:</strong>
            <p className="q-color">${parseFloat(part.part.price).toFixed(2)}</p>
          </div>
          <div className="d-flex justify-content-between align-items-baseline px-2">
            <strong className="q-color">Quantity:</strong>
            <p className="q-color">{part.quantity}</p>
          </div>
          <div className="d-flex justify-content-between align-items-baseline px-2">
            <strong className="q-color">SubTotal:</strong>
            <p className="q-color">${part.price}</p>
          </div>
          <div className="d-flex justify-content-between align-items-baseline px-2">
            <strong className="q-color mr-3">Comments:</strong>
            <p className="q-color text-center">{part.part.comments}</p>
          </div>
          <Box className="text-right px-2 mt-2">
              <IconButton
                className="cursor-pointer"
                tooltip="Re-ordering is disabled while filtering"
                onClick={handleDelete}
              >
                <DeleteOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.redAccent[500] }}
                />
              </IconButton>
          </Box>
          <hr />
        </Box>
    )
}

export default MiscCartItem;