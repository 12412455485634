

import { tsvParse, csvParse } from "d3-dsv";
import { timeParse } from "d3-time-format";


export const generatedUniqueId = function () {
	return Date.now().toString(36) + Math.random().toString(36).substr(2);
}


function parseData(parse) {
	return function (d) {
		d.date = parse(d.date);
		d.open = +d.open;
		d.high = +d.high;
		d.low = +d.low;
		d.close = +d.close;
		d.volume = +d.volume;

		return d;
	};
}

const parseDate = timeParse("%Y-%m-%d");


export const calculateSectionTotal = (products, sectionMarginPercentage, sectionDiscountPercentage) => {
	if (typeof (products) === 'object') {
		const sectionTotal = products?.reduce((total, product) => total + parseFloat(product.price), 0);

		if (sectionMarginPercentage !== null) {
			return sectionTotal + (sectionTotal * sectionMarginPercentage / 100);
		} else if (sectionDiscountPercentage !== null) {
			return sectionTotal - (sectionTotal * sectionDiscountPercentage / 100);
		}

		return sectionTotal;
	}
};

export const calculateOverallTotal = (sections) => {
	let overallTotal = 0;

	sections?.forEach((section) => {
		const { products, marginPercentage, discountPercentage } = section;
		const sectionTotal = calculateSectionTotal(products, marginPercentage, discountPercentage);
		overallTotal += sectionTotal;
	});

	return overallTotal;
};

export function generateUniqueId() {
	return Date.now().toString(36) + Math.random().toString(36).substr(2, 9);
}

export function containsAlphabetAfterHyphen(str) {
	const regex = /-[a-zA-Z]/;
	return regex.test(str);
}

export function formatCurrency(amount) {
	// Convert the amount to a fixed-point notation with 2 decimal places
	const fixedAmount = Number(amount).toFixed(2);

	// Split the string into whole number and decimal parts
	const [wholePart, decimalPart] = fixedAmount.split('.');

	// Add commas to the whole number part
	const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	// Combine the parts back together
	return `${formattedWholePart}.${decimalPart}`;
}