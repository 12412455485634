import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Paper, Typography } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useEffect, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { formatCurrency } from "./utils";
import { useNavigate } from "react-router-dom";
import html2canvas from 'html2canvas';
import { useSelector } from "react-redux";


const SalesPersonReport = (props) => {
    const salesPersonData = props.salesPersonData;
    const selectedSalesPerson = useSelector((state) => state.cart.selectedSalesPerson);
    const salesPeople = useSelector((state) => state.cart.salesPeople);
    const colors = props.colors;
    const selectedToDate = props.selectedToDate
    const selectedFromDate = props.selectedFromDate
    const [accordionExpanded, setAccordionExpanded] = useState(false);
    const [downloadingScreenshot, setDownloadingScreenshot] = useState(false);
    const [sortModel, setSortModel] = useState([
        {
            field: 'quantity',
            sort: 'desc',
        },
    ]);
    const navigate = useNavigate();
    const ToCaptureRef = useRef(null) // ref

    const handleQuotationItemDetailRouting = (event) => {
        navigate(`/quotation/${event.id}/${event.row?.get('quotationType')}/${event.row?.get('createdBy').id}`)
    };

    const columns = [
        {
            field: "createdAt",
            headerName: "Created At",
            width: 150,
            headerAlign: "center",
            align: "center", // Aligns content to the center
            valueGetter: (params) => `${params?.row.get('createdAt') || ""}`,
        },
        {
            field: "quotationNumber",
            headerName: "Quotation No.",
            width: 120,
            headerAlign: "center",
            align: "center", // Aligns content to the center
            valueGetter: (params) => `${params?.row.get('quotationNumber') || ""}`,
        },
        {
            field: "quotationStatus",
            headerName: "Status",
            width: 120,
            headerAlign: "center",
            align: "center", // Aligns content to the center
            valueGetter: (params) => `${params?.row.get('quotationStatus') || ""}`,
        },
        {
            field: "quotationType",
            headerName: "Type",
            width: 120,
            headerAlign: "center",
            align: "center", // Aligns content to the center
            valueGetter: (params) => `${params?.row.get('quotationType') || ""}`,
        },
        {
            field: "client",
            headerName: "Client",
            width: 180,
            headerAlign: "center",
            align: "center", // Aligns content to the center
            valueGetter: (params) => `${params?.row.get('selectedClient').data.companyName || ""}`,
        },
        {
            field: "sections",
            headerName: "Sections",
            width: 90,
            headerAlign: "center",
            align: "center", // Aligns content to the center
            valueGetter: (params) => `${params?.row.get('sections').length || ""}`,
        },
        {
            field: "discount",
            headerName: "Discount%",
            width: 120,
            headerAlign: "center",
            align: "center", // Aligns content to the center
            valueGetter: (params) => `${params?.row.get('sections').map((sec) => " " + (sec.discountPercentage ?? "0")) || "0"}`,
        },
        {
            field: "margin",
            headerName: "Margin%",
            width: 120,
            headerAlign: "center",
            align: "center", // Aligns content to the center
            valueGetter: (params) => `${params?.row.get('sections').map((sec) => " " + (sec.marginPercentage ?? "0")) || "0"}`,
        },
        {
            field: "netTotal",
            headerName: "Net Total",
            width: 120,
            headerAlign: "center",
            align: "center", // Aligns content to the center
            valueGetter: (params) => `$${formatCurrency(params?.row.get('totalPrice')) || ""}`,
        },
    ];

    useEffect(() => {
        // Function to hide the MUI X license warning
        const hideMuiXWarning = () => {
            // Try to find the element by its text content
            const warningElement = Array.from(document.body.getElementsByTagName('*'))
                .find(element => element.textContent === 'MUI X Missing license key');

            // If the element is found, hide it
            if (warningElement) {
                warningElement.style.display = 'none';
            }
        };

        // Call the function to hide the warning
        hideMuiXWarning();

        // Optional: Set up a MutationObserver to handle dynamically added elements
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.addedNodes.length) {
                    hideMuiXWarning();
                }
            });
        });

        observer.observe(document.body, { childList: true, subtree: true });

        // Cleanup function
        return () => {
            observer.disconnect();
        };
    }, []); // Empty dependency array means this effect runs once on mount

    const captureScreenshot = () => {
        var canvasPromise = html2canvas(ToCaptureRef.current, {
            useCORS: true
        });
        setDownloadingScreenshot(true);
        canvasPromise.then((canvas) => {
            var dataURL = canvas.toDataURL('image/png');
            
            // Create a formatted date string
            const formattedDateFrom = new Date(selectedFromDate).toLocaleDateString();
            const formattedDateTill = new Date(selectedToDate).toLocaleDateString();
            
            // Create the filename
            const filename = `${salesPersonName.get('firstName')}_${salesPersonName.get('lastName')}_${formattedDateFrom}_${formattedDateTill}.png`;
            
            // Create an image element from the data URL
            var img = new Image();
            img.src = dataURL;
            
            // Create a link element
            var a = document.createElement("a");
            a.innerHTML = "DOWNLOAD";
            a.target = "_blank";
            // Set the href of the link to the data URL of the image
            a.href = img.src;
            // Set the download attribute of the link to the new filename
            a.download = filename;
            // Append the link to the page
            document.body.appendChild(a);
            // Click the link to trigger the download
            a.click();
            // Remove the link from the page
            document.body.removeChild(a);
            setDownloadingScreenshot(false);
        });
    }

    const salesPersonName = salesPeople.filter((p) => p.id === selectedSalesPerson)[0]

    return (
        <>
            {salesPersonData.length > 0 && <Paper elevation={3} sx={{ mt: 5, padding: 2, borderRadius: 3, margin: 4 }}>
                <Box textAlign={'right'} mb={1}>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "5px 20px",
                        }}
                        disabled={!accordionExpanded || downloadingScreenshot}
                        onClick={captureScreenshot}
                    >
                        Download Report
                    </Button>
                </Box>
                <Accordion ref={ToCaptureRef} id="capture" onChange = {(e,expanded) => {if(expanded){setAccordionExpanded(true)}else{setAccordionExpanded(false)}}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1a-header"
                    >
                        <Typography mr={2} variant="h4" fontWeight={'bold'} color={colors.greenAccent[400]}>{salesPersonName.get('firstName')} {salesPersonName.get('lastName')}</Typography>
                        Report Summary From {new Date(selectedFromDate).toLocaleDateString()} to {new Date(selectedToDate).toLocaleDateString()}
                    </AccordionSummary>
                    <AccordionDetails>

                        <Box display={"flex"} border={'1px solid gray'} borderRadius={2} p={1} justifyContent={'space-around'}>
                            <Typography
                                variant="h4"
                                fontWeight="600"
                                sx={{ padding: "10px 10px 10 10px" }}
                                color={colors.greenAccent[400]}
                            >
                                Total Quotations Made:
                            </Typography>
                            <Typography
                                variant="h4"
                                fontWeight="800"
                                sx={{ padding: "10px 10px 10 10px" }}
                            >
                                {salesPersonData.length}
                            </Typography>
                        </Box>

                        <Box display={"flex"} justifyContent={'space-between'} borderBottom={`1px solid ${colors.primary[600]}`} pb={1}>
                            <Box display={"flex"} justifyContent={'space-between'}>
                                <Typography
                                    variant="h4"
                                    fontWeight="600"
                                    sx={{ padding: "10px 10px 0 10px" }}
                                    color={colors.greenAccent[400]}
                                >
                                    Total Quotations in Open Stage
                                </Typography>
                                <Typography
                                    variant="h4"
                                    fontWeight="800"
                                    sx={{ padding: "10px 10px 0 10px" }}
                                >
                                    {salesPersonData.filter((data) => data.get('status') === 'open').length}
                                </Typography>
                            </Box>
                            <Box display={"flex"} justifyContent={'space-between'}>
                                <Typography
                                    variant="h4"
                                    fontWeight="600"
                                    sx={{ padding: "10px 10px 0 10px" }}
                                    color={colors.greenAccent[400]}
                                >
                                    Total Open Stage Amount
                                </Typography>
                                <Typography
                                    variant="h4"
                                    fontWeight="800"
                                    sx={{ padding: "10px 10px 0 10px" }}
                                >
                                    ${formatCurrency(salesPersonData.filter((item) => item.get('status') === "open").flat().reduce((sum, item) => { return sum + (parseFloat(item.get("totalPrice")) || 0); }, 0).toFixed(2))}
                                </Typography>
                            </Box>
                        </Box>

                        <Box display={"flex"} justifyContent={'space-between'} borderBottom={`1px solid ${colors.primary[600]}`} pb={1}>
                            <Box display={"flex"} justifyContent={'space-between'}>
                                <Typography
                                    variant="h4"
                                    fontWeight="600"
                                    sx={{ padding: "10px 10px 0 10px" }}
                                    color={colors.greenAccent[400]}
                                >
                                    Average Margin% in Open Stage
                                </Typography>
                                <Typography
                                    variant="h4"
                                    fontWeight="800"
                                    sx={{ padding: "10px 10px 0 10px" }}
                                >
                                    {salesPersonData.filter(item => item.get('status') === 'open').flatMap(data => data.get("sections")).reduce((total, section, index, array) => index === array.length - 1 ? (total + (parseFloat(section.marginPercentage) || 0)) / array.length : total + (parseFloat(section.marginPercentage) || 0), 0).toFixed(2)}%
                                </Typography>
                            </Box>
                            <Box display={"flex"} justifyContent={'space-between'}>
                                <Typography
                                    variant="h4"
                                    fontWeight="600"
                                    sx={{ padding: "10px 10px 0 10px" }}
                                    color={colors.greenAccent[400]}
                                >
                                    Average Discount% in Open Stage
                                </Typography>
                                <Typography
                                    variant="h4"
                                    fontWeight="800"
                                    sx={{ padding: "10px 10px 0 10px" }}
                                >
                                    {salesPersonData.filter(item => item.get('status') === 'open').flatMap(data => data.get("sections")).reduce((total, section, index, array) => index === array.length - 1 ? (total + (parseFloat(section.discountPercentage) || 0)) / array.length : total + (parseFloat(section.discountPercentage) || 0), 0).toFixed(2)}%
                                </Typography>
                            </Box>
                        </Box>

                        <Box display={"flex"} justifyContent={'space-between'} borderBottom={`1px solid ${colors.primary[600]}`} pb={1}>
                            <Box display={"flex"} justifyContent={'space-between'}>
                                <Typography
                                    variant="h4"
                                    fontWeight="600"
                                    sx={{ padding: "10px 10px 0 10px" }}
                                    color={colors.greenAccent[400]}
                                >
                                    Total Quotations in Deposit Stage
                                </Typography>
                                <Typography
                                    variant="h4"
                                    fontWeight="800"
                                    sx={{ padding: "10px 10px 0 10px" }}
                                >
                                    {salesPersonData.filter(item => item.get('status') === "deposit").length}
                                </Typography>
                            </Box>

                            <Box display={"flex"} justifyContent={'space-between'}>
                                <Typography
                                    variant="h4"
                                    fontWeight="600"
                                    sx={{ padding: "10px 10px 0 10px" }}
                                    color={colors.greenAccent[400]}
                                >
                                    Total Deposit Stage Amount
                                </Typography>
                                <Typography
                                    variant="h4"
                                    fontWeight="800"
                                    sx={{ padding: "10px 10px 0 10px" }}
                                >
                                    ${formatCurrency(salesPersonData.flat().filter(item => item.get('status') === "deposit").reduce((sum, item) => { return sum + (parseFloat(item.get('totalPrice')) || 0) }, 0).toFixed(2))}
                                </Typography>
                            </Box>
                        </Box>
                        <Box display={"flex"} justifyContent={'space-between'} borderBottom={`1px solid ${colors.primary[600]}`} pb={1}>
                            <Box display={"flex"} justifyContent={'space-between'}>
                                <Typography
                                    variant="h4"
                                    fontWeight="600"
                                    sx={{ padding: "10px 10px 0 10px" }}
                                    color={colors.greenAccent[400]}
                                >
                                    Average Margin% in Deposit Stage
                                </Typography>
                                <Typography
                                    variant="h4"
                                    fontWeight="800"
                                    sx={{ padding: "10px 10px 0 10px" }}
                                >
                                    {salesPersonData.filter(item => item.get('status') === 'deposit').flatMap(data => data.get("sections")).reduce((total, section, index, array) => index === array.length - 1 ? (total + (parseFloat(section.marginPercentage) || 0)) / array.length : total + (parseFloat(section.marginPercentage) || 0), 0).toFixed(2)}%
                                </Typography>
                            </Box>
                            <Box display={"flex"} justifyContent={'space-between'}>
                                <Typography
                                    variant="h4"
                                    fontWeight="600"
                                    sx={{ padding: "10px 10px 0 10px" }}
                                    color={colors.greenAccent[400]}
                                >
                                    Average Discount in Deposit Stage
                                </Typography>
                                <Typography
                                    variant="h4"
                                    fontWeight="800"
                                    sx={{ padding: "10px 10px 0 10px" }}
                                >
                                    {salesPersonData.filter(item => item.get('status') === 'deposit').flatMap(data => data.get("sections")).reduce((total, section, index, array) => index === array.length - 1 ? (total + (parseFloat(section.discountPercentage) || 0)) / array.length : total + (parseFloat(section.discountPercentage) || 0), 0).toFixed(2)}
                                </Typography>
                            </Box>
                        </Box>

                        <Box display={"flex"} justifyContent={'space-around'} border={`1px solid ${colors.redAccent[600]}`} borderRadius={2} p={1} mt={2}>
                            <Typography
                                variant="h4"
                                fontWeight="600"
                                sx={{ padding: "10px 10px 10 10px" }}
                                color={colors.redAccent[400]}
                            >
                                Win Rate
                            </Typography>
                            <Typography
                                variant="h4"
                                fontWeight="800"
                                sx={{ padding: "10px 10px 10 10px" }}
                            >
                                {((salesPersonData.flat().filter(item => item.get('status') === "deposit").length) / (salesPersonData.length) * 100).toFixed(2)}%
                            </Typography>
                        </Box>

                    </AccordionDetails>
                </Accordion>
                <Box display={"flex"} mb={2}>
                    <Typography
                        variant="h4"
                        fontWeight="600"
                        sx={{ padding: "10px 10px 0 10px" }}
                        color={colors.greenAccent[400]}
                    >
                        Total Quotations Made:
                    </Typography>
                    <Typography
                        variant="h4"
                        fontWeight="800"
                        sx={{ padding: "10px 10px 0 10px" }}
                    >
                        {salesPersonData.length}
                    </Typography>
                </Box>
                <DataGridPro
                    sx={{
                        // disable cell selection style
                        ".MuiDataGrid-cell:focus": {
                            outline: "none"
                        },
                        // pointer cursor on ALL rows
                        "& .MuiDataGrid-row:hover": {
                            cursor: "pointer",
                        },
                        padding: 2,
                        boxShadow: 3,
                        border: 0,
                        borderColor: 'primary.light',
                        '& .MuiDataGrid-cell:hover': {
                            color: '#20c997',
                        },
                        fontSize: '18px'
                    }}
                    slotProps={{
                        filterPanel: {
                            sx: {
                                "& .MuiButtonBase-root": {
                                    backgroundColor: 'wheat'
                                }
                            }
                        }
                    }}
                    rows={salesPersonData}
                    columns={columns}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    onRowClick={handleQuotationItemDetailRouting}
                />
            </Paper>}
        </>
    )
}

export default SalesPersonReport;