import { Box, Button, MenuItem, Select, Typography, useTheme } from "@mui/material";
import BasicDatePicker from "./BasicDatePicker";
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import Parse from 'parse';
import { tokens } from "../theme";
import { Spinner } from "react-bootstrap";
import SalesPersonReport from "./SalesPersonReport";
import PanToolAltSharpIcon from '@mui/icons-material/PanToolAltSharp';
import { useDispatch, useSelector } from "react-redux";
import { setSalesDashboardData, setSalesPeople, setSelectedSalesPerson } from "../features/cart/cartSlice";

const SalesDashboard = () => {
    const theme = useTheme();
    const currentUser = Parse.User.current();
    const quotations = useSelector((state) => state.cart.salesDashboardData);
    const selectedSalesPerson = useSelector((state) => state.cart.selectedSalesPerson);
    const salesPeople = useSelector((state) => state.cart.salesPeople);
    const colors = tokens(theme.palette.mode);
    const currentDate = dayjs(new Date());
    const last14DaysDate = currentDate.subtract(1, 'month');
    const [selectedFromDate, setSelectedFromDate] = useState(last14DaysDate);
    const [selectedToDate, setSelectedToDate] = useState(dayjs());
    const [isLoading, setIsLoading] = useState(false);
    const [fetchingSalesData, setFetchingSalesData] = useState(false);
    const dispatch = useDispatch();

    const getSalesPeople = async () => {
        try {
            if (salesPeople.length === 0) {
                setIsLoading(true);
                const salesQuery = new Parse.Query(Parse.Object.extend("_User"));
                const res = await salesQuery.find();
                dispatch(setSalesPeople(res));
                setIsLoading(false);
            }
        } catch (error) {
            console.log('error: ', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getSalesPeople();
    }, []);

    const handleSalesPersonChange = (event) => {
        dispatch(setSelectedSalesPerson(event.target.value));
    }

    const getQuotationsData = async () => {
        try {
            setFetchingSalesData(true);
            const quotationsObj = Parse.Object.extend("Quotations");
            const query = new Parse.Query(quotationsObj)
                .include("client")
                .include("createdBy")
                .include("updatedBy")
                .descending("createdAt");
            query.limit(250);
            query.lessThanOrEqualTo("createdAt", new Date(selectedToDate));
            query.greaterThanOrEqualTo("createdAt", new Date(selectedFromDate));
            query.equalTo('createdBy', {
                __type: 'Pointer',
                className: '_User',
                objectId: selectedSalesPerson
            });
            const res = await query.find();
            dispatch(setSalesDashboardData(res));
            setFetchingSalesData(false);
        } catch (error) {
            console.log('Error fetching quotations:', error);
            setFetchingSalesData(false);
        }
    };

    return (
        <>
            {(currentUser.get('email') === "sunil@storemax.com.au" || currentUser.get('email') === "wahabtariq55@gmail.com" || currentUser.get('email') === "operations@storemax.com.au") && <Box>
                {!isLoading ? <Box>
                    <Box display={'flex'} alignItems={'baseline'}>
                        <Box mx={1}>
                            <BasicDatePicker date={selectedFromDate} setDate={setSelectedFromDate} label={"From Date"} />
                        </Box>
                        <Box mx={1}>
                            <BasicDatePicker date={selectedToDate} setDate={setSelectedToDate} label={"To Date"} />
                        </Box>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedSalesPerson}
                                label="Sales Person"
                                onChange={handleSalesPersonChange}
                            >
                                {salesPeople?.map((person) => {
                                    return <MenuItem key={person.id} value={person.id}>{person.get('firstName') + " " + person.get('lastName')}</MenuItem>
                                })}
                            </Select>
                            <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                <PanToolAltSharpIcon />
                                <Typography>Select Salesperson</Typography>
                            </Box>
                        </Box>
                        <Box mx={1}>
                            <Button
                                sx={{
                                    backgroundColor: colors.blueAccent[700],
                                    color: colors.grey[100],
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    padding: "5px 20px",
                                }}
                                disabled={!selectedSalesPerson}
                                onClick={getQuotationsData}
                            >
                                Generate Report
                            </Button>
                        </Box>
                    </Box>
                    {fetchingSalesData ? <Box mt={2} display={"flex"} justifyContent={'center'}>
                        <Spinner animation="border" role="status" size='sm' variant="primary" />
                    </Box> : <SalesPersonReport salesPersonData={quotations} colors={colors} selectedFromDate={selectedFromDate} selectedToDate={selectedToDate} />}
                </Box> : <Box display={"flex"} justifyContent={'center'}>
                    <Spinner animation="border" role="status" size='sm' variant="primary" />
                </Box>}
            </Box>}
        </>
    )
}

export default SalesDashboard;
