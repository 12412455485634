import React, { useState, useMemo } from "react";
import { useSelector, useDispatch } from 'react-redux'
import 'react-toastify/dist/ReactToastify.css';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ReactTooltip from "react-tooltip";
import { Box, IconButton, InputBase, useTheme } from "@mui/material";
import { tokens } from "../theme";
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RemoveCircleOutlinedIcon from '@mui/icons-material/RemoveCircleOutlined';
import { addToMiscCartItems } from "../features/cart/cartSlice";

export const COLUMN_NAME = "Column";
export const ARM_NAME = "Arm";
export const BASE_NAME = "Base";
export const BRACE_NAME = "Bracing";

const MiscPart = (props) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);
  const id = props.id;
  const part = props.part;
  const partQuantity = props.quantity;
  const [partName, setPartName] = useState(part.name);
  const [partDimensions, setPartDimensions] = useState(part.dimensions);
  const [partFinish, setPartFinish] = useState(part.finish);
  const [partPrice, setPartPrice] = useState(part.price);
  const [partComments, setPartComments] = useState(part.comments);
  const [partDuty, setPartDuty] = useState(part.duty);
	const [quantity, setQuantity] = useState(partQuantity);
	const [itemQuantityAvailable, setItemQuantityAvailable] = useState();
	const dispatch = useDispatch();
	const currentActiveSection = useSelector((state) => state.cart.sections.currentActive)
	const incremet = () => { setQuantity(quantity + 1); setItemQuantityAvailable(itemQuantityAvailable - 1) };
	const decrement = () => { setQuantity(quantity - 1); setItemQuantityAvailable(itemQuantityAvailable + 1) };

	const totalPrice = useMemo(() => {
		return partPrice * quantity;
	}, [quantity]);


	const addToCart = () => {
		const item = {
      id: id,
      part: {
      name: partName,
      dimensions: partDimensions,
      duty: partDuty,
      finish: partFinish,
      price: parseInt(partPrice),
      comments: partComments
    },
    quantity: quantity,
    price: totalPrice,
    section: currentActiveSection
  }
		dispatch(addToMiscCartItems(item));
	}

	return (
    <Box
      gridColumn="span 4"
      gridRow="span 3"
      backgroundColor={colors.primary[400]}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      pb="0.5em"
    >
      <Box className="d-flex flex-column justify-content-center h-100">
          <InputBase
            data-tip
            data-for="title"
            placeholder="Name"
            className="outlined border rounded px-2 mx-3 my-1"
            name="title"
            value={partName}
            onChange={(e) => setPartName(e.target.value)}
            onBlur={() => {}}
            />
            <InputBase
            data-tip
            data-for="title"
            placeholder="Dimensions"
            className="outlined border rounded px-2 mx-3 my-1"
            name="title"
            value={partDimensions}
            onChange={(e) => setPartDimensions(e.target.value)}
            onBlur={() => {}}
            />
            <InputBase
            data-tip
            data-for="title"
            placeholder="Duty"
            className="outlined border rounded px-2 mx-3 my-1"
            name="title"
            value={partDuty}
            onChange={(e) => setPartDuty(e.target.value)}
            onBlur={() => {}}
            />
            <InputBase
            data-tip
            data-for="title"
            placeholder="Finish"
            className="outlined border rounded px-2 mx-3 my-1"
            name="title"
            value={partFinish}
            onChange={(e) => setPartFinish(e.target.value)}
            onBlur={() => {}}
            />
            <InputBase
            data-tip
            type="number"
            data-for="title"
            placeholder="Price"
            className="outlined border rounded px-2 mx-3 my-1"
            name="title"
            value={partPrice}
            onChange={(e) => setPartPrice(e.target.value)}
            onBlur={() => {}}
            />

          <InputBase
            data-tip
            data-for="title"
            placeholder="Comments"
            className="outlined border rounded px-2 mx-3 my-1"
            name="title"
            value={partComments}
            onChange={(e) => setPartComments(e.target.value)}
            onBlur={() => {}}
            />
      </Box>

      <Box
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        width="100%"
      >
        <div className="d-flex align-items-center justify-content-between position-relative border rounded-pill">
          <IconButton onClick={decrement} disabled={partName.length === 0 || partPrice.length === 0 || quantity <= 0}>
            <RemoveCircleOutlinedIcon
              sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
            />
          </IconButton>
          <InputBase
            value={quantity}
            onChange={(e) => {}}
            onBlur={() => {}}
            sx={{ flex: 1 }}
          />
          <IconButton disabled={partName.length === 0 || partPrice.length === 0} onClick={incremet}>
            <AddCircleOutlinedIcon
              sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
            />
          </IconButton>
        </div>
        <Box mx="0.2em">
          Net Price:{" "}
          <strong>{(Math.round(totalPrice * 100) / 100).toFixed(2)}</strong>
        </Box>
        <ReactTooltip id="title required" effect="solid" place="top">
          {"Add to Cart"}
        </ReactTooltip>
        <IconButton
          className="cursor-pointer"
          disabled={quantity <= 0}
          onClick={addToCart}
          data-tip
          data-for="title required"
        >
          <ShoppingCartIcon
            sx={{
              fontSize: "26px",
              color: quantity > 0 ? colors.greenAccent[500] : colors.grey[500],
            }}
          />
        </IconButton>
      </Box>
    </Box>
  );
};
export default MiscPart;
