import { createSlice } from '@reduxjs/toolkit'
import { ToastContainer, toast } from 'react-toastify';
import { ParseObject } from '../../components/ExistingQuotation';
import SalesDashboard from '../../components/SalesDashboard';

const customId = "custom-id";
const customId2 = "custom-id2";
const customId3 = "custom-id3";
const initialState = {
  sections: { currentActive: 1, sectionCount: 1 },
  cartItems:[],
  miscCantileverItems:[],
  user: {id: '', loggedIn: localStorage.getItem('user')},
  quotationItems: [{id: '', part: {}, quantity: 0, section: 1}],
  pickListQuotationParts: [],
  quotationNumber: '',
  revisionQuotation:{},
  selectedClient: null,
  quotationType: [], 
  quotationPercentageToWin: 50,
  revisionInProgress: false,
  quotationsData: [],
  salesDashboardData: [],
  selectedSalesPerson: '',
  salesPeople: []
};

export const cartSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      if (state.cartItems?.length >= 1) {
        state.sections.currentActive = action.payload.section;
        state.sections.sectionCount = Math.max(state.sections.sectionCount, action.payload.section);
        if (
          state.cartItems[0].part.get("finish") !==
            action.payload.part.get("finish") &&
          state.cartItems[0].section === state.sections.currentActive
        ) {
          if (action.payload.part.get("className") === 'CantileverParts') {
            toast.error("Adding contrary polished piece", {
              toastId: customId,
            });
          }
        }
        const baseExists = state.cartItems.find(
          (p) => p.part.get("name") === "Base"
        );
        const ArmExists = state.cartItems.find(
          (p) => p.part.get("name") === "Arm"
        );
        if (
          baseExists !== undefined &&
          action.payload.part.get("name") === "Arm" &&
          action.payload.part.get("depth") !==
            Object.values(baseExists)[1].get("armToSuitBase").replace(/ /g, "")
        ) {
          if (action.payload.part.get("className") === 'CantileverParts'){
            toast.error("Adding contrary Arm relative to already selected Base", {
            toastId: customId2,
          });
        }
        }
        if (
          ArmExists !== undefined &&
          action.payload.part.get("name") === "Base" &&
          action.payload.part.get("armToSuitBase").replace(/ /g, "") !==
            Object.values(ArmExists)[1].get("depth")
        ) {
          if (action.payload.part.get("className") === 'CantileverParts')
          {toast.error("Adding contrary Arm relative to already selected Base", {
            toastId: customId3,
          });}
        }
        const existingItem = state.cartItems.find(
          (item) =>
            item.id === action.payload.id &&
            item.section === state.sections.currentActive
        );
        if (existingItem) {
          console.log('existingItem: ',existingItem.section);
          existingItem.quantity = action.payload.quantity;
          existingItem.price = action.payload.price;
          existingItem.section = state.sections.currentActive;
          console.log('existingItem???????: ',existingItem.section);
        } else {
          state.cartItems.push(action.payload);
        }
      } else {
        state.cartItems.push(action.payload);
      }
    },
    updateCartItem: (state, action) => {
      const externalVendor = action.payload.externalVendor;
      const existingItem = state.cartItems.find(
        (item) => {
          return (item.id === action.payload.id && item.section === action.payload.section)
        }
      );
      if (existingItem) {
        existingItem.quantity = parseInt(action.payload.quantity);
        existingItem.price = existingItem.part.get("price") * parseInt(action.payload.quantity);
      }
      if (existingItem && externalVendor) {
        existingItem.price = action.payload.price;
        existingItem.quantity = parseInt(action.payload.quantity);
      }
    },
    removeFromCart: (state, action) => {
      console.log('>> ',action.payload);
      state.cartItems.forEach((item, index) => {
        if (item.id === action.payload.id && item.section === action.payload.section) {
          state.cartItems.splice(index, 1);
        }
      });
    },
    addAnotherSection: (state, action) => {
      state.sections.sectionCount++;
      state.sections.currentActive = state.sections.sectionCount;
    },
    deleteSection: (state, action) => {
      console.log('action: ',action.payload)
      const cartItemsClone = [...state.cartItems];
      const cartItemsMiscClone = [...state.miscCantileverItems];
      const updatedCart = cartItemsClone.filter((item) => item.section !== action.payload)
      const updatedCartMisc = cartItemsMiscClone.filter((item) => item.section !== action.payload);
      state.cartItems = updatedCart
      state.miscCantileverItems = updatedCartMisc
      state.sections.sectionCount--;
      state.sections.currentActive = action.payload-1
    },
    currentActiveSection: (state, action) => {
      state.sections.currentActive = action.payload;
    },
    addProfileInformation: (state, action) => {
      state.user.id = action.payload.id;
      state.user.loggedIn = action.payload.loggedIn;
    },
    emptyCart: (state, action) => {
      state.cartItems = [];
      state.miscCantileverItems = [];
    },
    initializeQuotationItems: (state, action) => {
      const stateCopy = Object.assign({}, state);
      stateCopy.quotationItems = action.payload;
      state.quotationItems = stateCopy.quotationItems;
    },
    addToMiscCartItems: (state, action) => {
      if (state.miscCantileverItems.length > 0) {
        const existingItem = state.miscCantileverItems.find(
          (item) =>
            item.id === action.payload.id &&
            state.miscCantileverItems[0].section ===
              state.sections.currentActive
        );
        if (existingItem) {
          existingItem.quantity = action.payload.quantity;
          existingItem.price = action.payload.price;
          existingItem.section = action.payload.section;
        } else {
          state.miscCantileverItems.push(action.payload);
        }
      } else {
        const stateCopy = Object.assign({}, state);
        stateCopy.miscCantileverItems.push(action.payload);
        state.miscCantileverItems = stateCopy.miscCantileverItems;
      }
    },
    updateMiscCartItems: (state, action) => {
      const stateCopy = Object.assign({}, state);
      stateCopy.miscCantileverItems = (action.payload);
      state.miscCantileverItems = stateCopy.miscCantileverItems;
    },
    deleteMiscCartItem: (state, action) => {
      const stateCopy = Object.assign({}, state);
      const filteredArray = stateCopy.miscCantileverItems.filter((item) => item.id !== action.payload.id);
      state.miscCantileverItems = filteredArray;
    },
    updatePickListQuotationParts: (state, action) => {
      state.pickListQuotationParts = action.payload;
    },
    patchQuotationNumber: (state, action) => {
      state.quotationNumber = action.payload;
    },
    setSelectedClient: (state, action) => {
      state.selectedClient = action.payload;
    },
    setRevisionInProgress: (state, action) => {
      state.revisionInProgress = action.payload;
    },
    setRevisionQuotation: (state, action) => {
      state.revisionQuotation = action.payload;
    },
    setSelectedQuotationType: (state, action) => {
      state.quotationType = action.payload;
    },
    setSelectedPercentageToWin: (state, action) => {
      state.quotationPercentageToWin = action.payload
    },
    resetState: (state, action) => {
      state.sections = { currentActive: 1, sectionCount: 1 };
      state.cartItems =[];
      state.miscCantileverItems =[];
      state.user = {id: '', loggedIn: localStorage.getItem('user')};
      state.quotationItems = [{id: '', part: {}, quantity: 0, section: 1}];
      state.pickListQuotationParts = [];
      state.quotationNumber = '';
      state.selectedClient = null;
      state.quotationType = [];
      state.quotationPercentageToWin = 50;
      state.revisionInProgress = false;
      state.revisionQuotation = null;
    },
    setQuotations: (state, action) => {
      state.quotationsData = action.payload
    },
    setSalesDashboardData: (state, action) => {
      state.salesDashboardData= action.payload
    },
    setSelectedSalesPerson: (state, action) => {
      state.selectedSalesPerson= action.payload
    }, 
    setSalesPeople: (state,action) => {
      state.salesPeople= action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const { addToCart, removeFromCart, addAnotherSection, currentActiveSection, addProfileInformation, emptyCart, updateCartItem, initializeQuotationItems, addToMiscCartItems, deleteSection, updatePickListQuotationParts, updateMiscCartItems, deleteMiscCartItem, patchQuotationNumber, setSelectedClient, setSelectedQuotationType, resetState, setSelectedPercentageToWin, setRevisionInProgress, setRevisionQuotation, setQuotations, setSalesDashboardData, setSelectedSalesPerson, setSalesPeople } = cartSlice.actions

export default cartSlice.reducer