import {
  pdf,
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import { ARM_NAME, BASE_NAME, BRACE_NAME, COLUMN_NAME } from "./QuotationCartItem";
import storemaxLogo from "../assets/images/storemax.png";
import { useEffect, useState } from "react";
import { Backdrop, Box, Button, Fade, InputBase, InputLabel, MenuItem, Modal, Select, Typography, useTheme } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { tokens } from "../theme";
import { Spinner } from "react-bootstrap";
import PicklistPart from "./PicklistPartComponent";
import { useDispatch, useSelector } from "react-redux";
import { updatePickListQuotationParts } from "../features/cart/cartSlice";
import FormControl from "@mui/material/FormControl";
import { BEAM, FRAME } from "./PalletRackingPart";
import { FRAMES } from "../static/PickSlipAccessories";
import ArialUnicodeMS from '../assets/fontFamilies/Noto_Sans/NotoSans-Medium.ttf'; // Import the font file
import { toast } from "react-toastify";
import { ParseObject } from "./ExistingQuotation";

Font.register({
	family: 'Arial Unicode MS',
	src: ArialUnicodeMS,
});

const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    height: "100% !important",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  header: {
    marginVertical: "1%",
  },
  companyInfo: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: '10'
  },
  clientInfo: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  container: {
    padding: 20,
    flexGrow: 1,
  },
  rowHeader: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "lightgray",
    padding: "6px",
    borderRadius: "4px",
  },
  rowHeaderPicklistItems: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "lightgray",
    padding: "6px",
    borderRadius: "4px",
    marginTop: '10px',
  },
  row: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: "5px",
    fontFamily: 'Arial Unicode MS'
  },
  containerBillInfo: {
    width: "100%",
    padding: "10px",
    marginBottom: "15%",
    borderBottom: "1px solid lightgray",
    borderTop: "1px solid lightgray",
  },
  totalBillInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginVertical: "5px",
  },
  rowBetween: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: "space-between",
	},
  itemText: {
    fontSize: "10px",
  },
  quantityText: {
    fontSize: "10px",
    minWidth: '20px',
    marginRight: "10px",
  },
  totalPriceText: {
    fontSize: "10px",
    marginRight: "40px",
  },
  totalPriceValue: {
    fontSize: "10px",
    marginRight: "20px",
  },
});

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function Picklist(props) {
  const quotationItem = props.quotationItem;
  const disableDownloadButton = props.disableDownloadButton
  const [openPickListModal, setOpenPickListModal] = useState(false);
  const [vendorEmail, setVendorEmail] = useState('');
  const [vendorName, setVendorName] = useState();
  const [quoteReferenceNumber, setQuoteReferenceNumber] = useState(quotationItem?.get("quotationNumber"));
  const quotationParts = props.quotationParts;
  const revision = props.revision;
  const dispatch = useDispatch();
  const finalized = props.finalized;
  const pickListQuotationParts = useSelector((state) => state.cart.pickListQuotationParts);
  const [quotationPartsUpdated, setQuotationPartsUpdated] = useState([]);
  const quotationSections = props.quotationSections;
  const currentDate = quotationItem?.get("createdAt")?.toString().slice(0, 15);
  const quotationNumber = quotationItem?.get("quotationNumber");
  const [quotationPartDict, setQuotationPartDict] = useState({});
  const [columnPart, setColumnPart] = useState();
  const [basePart, setBasePart] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [suburbAndState, setSuburbAndState] = useState("")
  const theme = useTheme();
  const client = quotationItem.get("client") ? quotationItem.get("client") : new ParseObject(quotationItem?.get('selectedClient')?.data);
  if (!client) {
	  toast.warning('Please update the quotation and select a client first')
  }
	const colors = tokens(theme.palette.mode);
  const GreenSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: colors.greenAccent[600],
      '&:hover': {
        backgroundColor: alpha(colors.greenAccent[600], theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: colors.greenAccent[600],
    },
  }));
  const [calculateNutsAndBoltsEnabled, setCalculateNutsAndBoltsEnabled] = useState(false);
  const [picklistDocType, setPicklistDocType] = useState('internal');

  useEffect(() => {
    if (quotationParts.length > 0) {
      if (revision && finalized) {
        dispatch(updatePickListQuotationParts([...quotationParts]));
      } else if (!revision) {
        dispatch(updatePickListQuotationParts([...quotationParts]));
      }
    }
  }, [quotationParts]);

  const checkForFrameDims = (string) => {
    const regex = new RegExp(Object.keys(FRAMES).join("|"));
    const match = string.match(regex);

    if (match) {
      console.log("At least one item from the array is part of the string.");
      return match[0]
    } else {
      console.log("No item from the array is part of the string.");
      return false
    }
  }

  const handleClose = () => setOpenPickListModal(false);

  //   PICKLIST CONFIGURATIONS

  const basePlateDimMapping = {'900mm': '1389mm', '1200mm': '1689mm', '1500mm': '1989mm'};

  const findClosestValue = (input) => {
    let closestKey = null;
    let minDifference = Infinity;

    for (const key in basePlateDimMapping) {
        const keyNumber = parseFloat(key);
        const inputNumber = parseFloat(input);
        const difference = Math.abs(keyNumber - inputNumber);

        if (difference < minDifference) {
            minDifference = difference;
            closestKey = key;
        };
    }
    return basePlateDimMapping[closestKey];
}

  useEffect(() => {
    const quotationPartsDict = {};
    
    quotationParts?.map((part) => {
      return quotationPartsDict[part.section] = {}
    });

    quotationParts?.map((part) => {
      if (part.name === BRACE_NAME) {
        return quotationPartsDict[part.section][
          part.name + "_" + part?.layout
        ] = {quantity: part.quantity, finish: part.finish};
      } else if (part.name === FRAME || part.name === BEAM) {
        return quotationPartsDict[part.section][
          part.name
        ] = {quantity: part.quantity, finish: part.finish, dims: part.description};
      } else {
        return quotationPartsDict[part.section][part.name] = {quantity: part.quantity, finish: part.finish};
      }
    });
    setQuotationPartDict(quotationPartsDict);
    const column = quotationParts?.filter((part) => part?.name === "Column")[0];
    const base = quotationParts?.filter((part) => part?.name === "Base")[0];
    setColumnPart(column);
    setBasePart(base);
  }, [quotationParts]);


  const calculateColumnPartQuantityForPostAndBases = (
    columnPart,
    quotationPartDict,
    sectionIndex
  ) => {
    const heightFactors = {
      3000: 4,
      2000: 5,
      2972: 4,
      3048: 4,
      3657: 5,
      4267: 12,
      5029: 12,
      5791: 12,
      4867: 12,
    };
  
    const heightWithoutMM = (height) => parseInt(height.replace('mm', ''), 10);
  
    const closestHeight = Object.keys(heightFactors).reduce((prev, curr) => {
      const parsedPrev = heightWithoutMM(prev);
      const parsedCurr = heightWithoutMM(curr);
      const parsedColumnHeight = heightWithoutMM(columnPart?.height.trim());
  
      return (
        Math.abs(parsedCurr - parsedColumnHeight) <
        Math.abs(parsedPrev - parsedColumnHeight)
          ? curr
          : prev
      );
    });
  
    const quantity = quotationPartDict[sectionIndex + 1]?.Column?.quantity;
    const multiplicationFactor = heightFactors[heightWithoutMM(closestHeight)];
  
    return quantity ? quantity * multiplicationFactor : null;
  };
  

  const calculateColumnPartQuantityPostsAndBracingBrackets = (
    columnPart,
    quotationPartDict,
    sectionIndex
  ) => {
    const heightFactors = {
      3000: 6,
      2000: 4,
      2972: 6,
      3048: 6,
      3657: 8,
      4267: 8,
      5029: 10,
      5791: 10,
      4867: 10,
    };
  
    const heightWithoutMM = (height) => parseInt(height.replace('mm', ''), 10);
  
    const closestHeight = Object.keys(heightFactors).reduce((prev, curr) => {
      const parsedPrev = heightWithoutMM(prev);
      const parsedCurr = heightWithoutMM(curr);
      const parsedColumnHeight = heightWithoutMM(columnPart?.height.trim());
  
      return (
        Math.abs(parsedCurr - parsedColumnHeight) <
        Math.abs(parsedPrev - parsedColumnHeight)
          ? curr
          : prev
      );
    });
  
    const quantity = quotationPartDict[sectionIndex + 1]?.Column?.quantity;
    const multiplicationFactor = heightFactors[heightWithoutMM(closestHeight)];
  
    return quantity ? quantity * multiplicationFactor : null;
  };

  const MyDocument = () => (
    <Document>
      {Array.apply(null, {length: quotationSections[0].sectionCount}).map(
        (section, sectionIndex) => {
          return quotationPartDict[sectionIndex+1] && <Page key={sectionIndex} size="a4" style={styles.page}>
            <View style={styles.container}>
              <View style={styles.header}>
              <View style={styles.companyInfo}>
							<View>
								<Image
									style={{
										width: "180px",
										marginLeft: "-20",
										marginTop: "-50px",
									}}
									src={storemaxLogo}
								/>
							</View>
							<View>
									<Text
										style={{
											fontSize: "10px",
											marginVertical: "2",
											fontFamily: 'NOTO-SEMI-BOLD'
										}}
									>
										STOREMAX AUSTRALIA PTY. LTD.
									</Text>
									<Text style={{ fontSize: "8px", marginVertical: "2", fontFamily: 'NOTO-SEMI-BOLD', marginBottom: '5' }}>
										ABN: 42 162 922 107
									</Text>
									<Text
										style={{
											fontSize: "8px",
											marginVertical: "1",
										}}
									>
										Date: {currentDate}
									</Text>
							</View>
						</View>

                {columnPart?.duty.length > 0 && <View
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Text style={{ fontSize: "10px" }}>
                   {columnPart?.duty ?  `${columnPart?.duty} Duty Cantilever` : null} 
                  </Text>
                </View>}

                <View
                  style={{
                    border: "1px solid lightgray",
                    borderRadius: "4px",
                    padding: "8px",
                    textAlign: "center",
                  }}
                >
                  {vendorName?.trim().length > 0 && <Text style={{ fontSize: "10px" }}>
                    Prepared For:{" "}
                    {picklistDocType === 'internal' ? "Storemax Warehouse" : vendorName}
                  </Text>}

                  <Text style={{ fontSize: "10px" }}>
                    {" "}
                    {picklistDocType !== 'internal' && "Suburb & State: " + suburbAndState}
                  </Text>

                  {picklistDocType === 'internal' && <Text style={{ fontSize: "10px" }}>
                    {vendorEmail}
                  </Text>}
                </View>

                <Text style={{ fontSize: "10px", marginTop: "8px" }}>
                  Order Reference Number:{" "}
                  {quoteReferenceNumber}
                </Text>

                {picklistDocType === 'internal' && <Text style={{ fontSize: "10px", marginTop: "5px" }}>
                  Customer: {client?.get("companyName")?.length > 0 ? client?.get("companyName") : client?.get("firstName")}{" "}
                    {client?.get("lastName")}{" "}{client?.get("suburb")}{" "}{client?.get("state")}
                </Text>}

                {picklistDocType === 'internal' && <Text style={{ fontSize: "10px", marginTop: "5px" }}>
                  Address: {client?.get("address")}
                </Text>}

                <Text style={{ fontSize: "10px", marginTop: "5px" }}>
                  Section - {sectionIndex + 1}
                </Text>
              </View>

              <View style={styles.rowHeader}>
                <Text style={styles.itemText}>Name</Text>
                <View style={styles.rowBetween}>
                  <Text style={{ fontSize: "10px", marginRight: "30px" }}>
                    Finish
                  </Text>
                  <Text style={styles.quantityText}>Quantity</Text>
                </View>
              </View>

              {pickListQuotationParts?.map((part, index) => (
                part?.name && part.section === sectionIndex + 1 && ((picklistDocType === 'internal' && part.inventoryQuantity - part.quantity > 0) || (picklistDocType === "external" && part.inventoryQuantity - part.quantity < 0)) &&
                  <View key={index} style={styles.row}>
                    <>
                      {part?.className === 'CantileverParts' && part.quantity > 0 && picklistDocType === 'internal' && part.inventoryQuantity - part.quantity > 0
                      ?
                      <Text style={styles.itemText}>
                        {part.name === COLUMN_NAME ?
                          "Column " + part.height + " High/ " + part.width
                         : part.name === BASE_NAME ? 
                          part.duty !== "Light" ? "Base Side Plates " + part.width + " " + part.sides + " sides for " + part.armToSuitBase + " Arms " : "Base " + part.width + " " + part.sides + " sides for " + part.armToSuitBase + " Arms "
                         : part.name === ARM_NAME ? 
                          "Arm H " + part.height + " - " + part.depth
                         : part.name === BRACE_NAME ? 
                         "Brace " + part.bayCenter.split(" ")[0] + " " + part.bayCenter.split(" ")[1] + " c/c " + part.width
                         : ""}
                      </Text> 
                      : 
                      part?.className === 'CantileverParts' && part.quantity > 0 && picklistDocType === "external" && part.inventoryQuantity - part.quantity < 0
                      ? 
                      <Text style={styles.itemText}>
                        {part.name === COLUMN_NAME ?
                          "Column " + part.height + " High/ " + part.width
                         : part.name === BASE_NAME ? 
                          part.duty !== "Light" ? "Base Side Plates " + part.width + " " + part.sides + " sides for " + part.armToSuitBase + " Arms " : "Base " + part.width + " " + part.sides + " sides for " + part.armToSuitBase + " Arms "
                         : part.name === ARM_NAME ? 
                          "Arm H " + part.height + " - " + part.depth
                         : part.name === BRACE_NAME ? 
                         "Brace " + part.bayCenter.split(" ")[0] + " " + part.bayCenter.split(" ")[1] + " c/c " + part.width
                         : ""}
                      </Text> 
                      :
                      part.quantity && part?.className !== 'CantileverParts' && picklistDocType === 'internal' && part.inventoryQuantity - part.quantity > 0 
                      ? 
                      <Text style={styles.itemText}>{part?.name} {part?.description}</Text> 
                      :
                      part.quantity && part?.className !== 'CantileverParts' && picklistDocType === "external" && part.inventoryQuantity - part.quantity < 0
                      ?
                      <Text style={styles.itemText}>{part?.name} {part?.description} {(part?.name === "Frame" || part?.name === "Beam") ?  `/${part.brand}` : null}</Text> 
                      : 
                      <Text style={styles.itemText}>{"Please view the"} { picklistDocType === 'internal' ? "external" : 'internal' } {"pickslip for this item"}</Text>
                      }
                      {part.quantity > 0 && 
                        
                        <View style={styles.rowBetween}>
													{(part.name !== "Frame" && part.name !== "Beam") ? <Text
														style={{ fontSize: "10px", marginRight: "50px" }}
													>
														{part.finish === "Blue"
															? "Powder Coated"
															: part.finish === "Galv" ? 'Galvanized' : null }
													</Text> : part.name === "Frame" ? <Text style={{ fontSize: "10px", marginRight: "50px" }}>
                            {part.finish}
                            </Text> : part.name === "Beam" ? <Text style={{ fontSize: "10px", marginRight: "50px" }}>
                            {part.finish}
                            </Text> : <></>}
													<Text style={styles.quantityText}>{picklistDocType === 'internal' && part.inventoryQuantity - part.quantity > 0 ? (part.name === BASE_NAME && 
                          part.duty !== "Light") ? part.quantity * 2 : part.quantity : picklistDocType === "external" && part.inventoryQuantity - part.quantity < 0 ? part.quantity : "N/A"}
                        </Text>
												</View>
                      }
                    </>
                </View>
              ))}

            {/* PICKLIST ITEMS */}

            {calculateNutsAndBoltsEnabled && Object.keys(quotationPartDict[sectionIndex + 1] ? quotationPartDict[sectionIndex + 1] : {})?.includes('Column') && 
            
            <View>
              <View style={styles.rowHeaderPicklistItems}>
                <Text style={styles.itemText}>Name</Text>
                <View style={styles.rowBetween}>
                  <Text style={{ fontSize: "10px", marginRight: "30px" }}>
                    Finish
                  </Text>
                  <Text style={styles.quantityText}>Quantity</Text>
                </View>
              </View>

              <View style={styles.row}>
                <Text style={styles.itemText}>Brace Brackets</Text>
                <View style={styles.rowBetween}>
                  <Text style={{ fontSize: "10px", marginRight: "50px" }}>{quotationPartDict[sectionIndex+1]["Bracing_Horizontal"]?.finish === "Galv" ? "Galvanized" : "Powder Coated" }</Text>
                  <Text style={styles.quantityText}>
                    {quotationPartDict[sectionIndex+1]["Bracing_Horizontal"]?.quantity * 2}
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <Text style={styles.itemText}>
                  {"Base kits "}{basePart?.sides?.trim() === "1" ? "S/S" : "D/S"}
                </Text>
                <View style={styles.rowBetween}>
                  <Text style={{ fontSize: "10px", marginRight: "50px" }}>{columnPart?.finish === "Galv" ? "Galvanized" : "Zinc" }</Text>
                  <Text style={styles.quantityText}>
                    {quotationPartDict[sectionIndex+1]["Base"]?.quantity}
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <Text style={styles.itemText}>{(columnPart.duty === "Medium" || columnPart.duty === "Heavy") ? "Bolts & Nuts for Posts & Bracing Brackets - M12*130" : "Bolts & Nuts for Posts & Bracing Brackets - M10*80"}</Text>
                <View style={styles.rowBetween}>
                <Text style={{ fontSize: "10px", marginRight: "50px" }}>{columnPart?.finish === "Galv" ? "Galvanized" : "Zinc" }</Text>
                  <Text style={styles.quantityText}>
                    {calculateColumnPartQuantityPostsAndBracingBrackets(columnPart, quotationPartDict, sectionIndex)}
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <Text style={styles.itemText}>{"Bolts & Nuts for Bracing - M10*30"}</Text>
                <View style={styles.rowBetween}>
                  <Text style={{ fontSize: "10px", marginRight: "50px" }}>{quotationPartDict[sectionIndex+1]["Bracing_Horizontal"]?.finish === "Galv" ? "Galvanized" : "Zinc" }</Text>
                  <Text style={styles.quantityText}>
                    {quotationPartDict[sectionIndex+1]["Bracing_Horizontal"]?.quantity * 2}
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <Text style={styles.itemText}>{(columnPart.duty === "Medium" || columnPart.duty === "Heavy") ? "Arm Pin - Ф30*130" : "Arm pin Ф16*90"}</Text>
                <View style={styles.rowBetween}>
                  <Text style={{ fontSize: "10px", marginRight: "50px" }}>{quotationPartDict[sectionIndex+1]["Arm"]?.finish === "Galv" ? "Galvanized" : "Zinc" }</Text>
                  <Text style={styles.quantityText}>
                    {quotationPartDict[sectionIndex+1]["Arm"]?.quantity}
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <Text style={styles.itemText}>Arm Safety Lock Pin - Ф40*4.0</Text>
                <View style={styles.rowBetween}>
                  <Text style={{ fontSize: "10px", marginRight: "50px" }}>Zinc</Text>
                  <Text style={styles.quantityText}>
                    {quotationPartDict[sectionIndex+1]["Arm"]?.quantity}
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <Text style={styles.itemText}>{(columnPart.duty === "Medium" || columnPart.duty === "Heavy") ? "Dynabolt - M16x100mm" : "Dynabolt - M12x80mm"}</Text>
                <View style={styles.rowBetween}>
                  <Text style={{ fontSize: "10px", marginRight: "50px" }}>{quotationPartDict[sectionIndex+1]["Base"]?.finish === "Galv" ? "Galvanized" : "Zinc"}</Text>
                  <Text style={styles.quantityText}>
                    {basePart?.sides.trim() === "1" && (basePart.duty === "Medium" || basePart.duty === "Heavy") ? quotationPartDict[sectionIndex+1]["Base"]?.quantity * 3 : basePart?.sides.trim() === "2" && (basePart.duty === "Medium" || basePart.duty === "Heavy") ? quotationPartDict[sectionIndex+1]["Base"]?.quantity * 4 : basePart?.sides.trim() === "1" && basePart.duty === "Light" ? quotationPartDict[sectionIndex+1]["Base"]?.quantity * 2 : basePart?.sides.trim() === "2" && basePart.duty === "Light" ? quotationPartDict[sectionIndex+1]["Base"]?.quantity * 4 : null}
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <Text style={styles.itemText}>{(columnPart.duty === "Medium" || columnPart.duty === "Heavy") ? "Packing Shims - 100x200x2.0" : "Packing Shims - 120 x 60 x 2mm"}</Text>
                <View style={styles.rowBetween}>
                  <Text style={{ fontSize: "10px", marginRight: "50px" }}>{quotationPartDict[sectionIndex+1]["Base"]?.finish === "Galv" ? "Galvanized" : "Zinc"}</Text>
                  <Text style={styles.quantityText}>
                    {quotationPartDict[sectionIndex+1]["Base"]?.quantity * 3}
                  </Text>
                </View>
              </View>

              <View style={styles.row}>
                <Text style={styles.itemText}>Load Sign</Text>
                <Text style={styles.quantityText}>1</Text>
              </View>
            </View>
            }

            {calculateNutsAndBoltsEnabled && Object.keys(quotationPartDict[sectionIndex + 1] ? quotationPartDict[sectionIndex + 1] : {}).includes('Frame') && 
              <View>
                <View style={styles.rowHeaderPicklistItems}>
                  <Text style={styles.itemText}>Name</Text>
                  <View style={styles.rowBetween}>
                    <Text style={{ fontSize: "10px", marginRight: "30px" }}>
                      Finish
                    </Text>
                    <Text style={styles.quantityText}>Quantity</Text>
                  </View>
                </View>

                {checkForFrameDims(quotationPartDict[sectionIndex+1]['Frame']?.dims) && FRAMES[checkForFrameDims(quotationPartDict[sectionIndex+1]['Frame']?.dims)].map((item) => {
                  return <View style={styles.row}>
                  <Text style={styles.itemText}>{item.name} {item.dims}</Text>
                  <Text style={styles.quantityText}>{quotationPartDict[sectionIndex+1]['Frame']?.quantity * item.quantity}</Text>
                </View>
                })}

                {(quotationPartDict[sectionIndex+1]['Beam']?.dims.includes("L 2591mm W 100") || quotationPartDict[sectionIndex+1]['Beam']?.dims.includes("L 2743mm W 100")) && 
                <View style={styles.row}>
                  <Text style={styles.itemText}>Spring Lock</Text>
                  <Text style={styles.quantityText}>{quotationPartDict[sectionIndex+1]['Beam']?.quantity * 2}</Text>
                </View>
                }

              </View>
            }
            </View>
          </Page>
        }
      )}
    </Document>
  );

  const saveFile = () => {
    setIsLoading(true);
    setOpenPickListModal(false);
    pdf(<MyDocument />)
      .toBlob()
      .then((blob) => {
        saveAs(blob, 'picklist_ ' + picklistDocType + "_" + quoteReferenceNumber + ".pdf");
        setIsLoading(false);
      });
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openPickListModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPickListModal}>
          <Box sx={modalStyle}>
            <Typography
              id="transition-modal-description"
              className="secondary-color text-center"
              sx={{ mt: 2 }}
            >
              Picklist Metadata
            </Typography>

            <FormControl style={{width: '100%'}} className=" my-4 text-center">
              <InputLabel id="demo-simple-select-label">Picklist Creation Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={picklistDocType}
                label="Finish"
                onChange={(e) => setPicklistDocType(e.target.value)}
              >
                <MenuItem value={"external"}>External Vendor</MenuItem>
                <MenuItem value={"internal"}>Internal Warehouse Inventory</MenuItem>
              </Select>
            </FormControl>

            <Box sx={{ width: "100%" }}>
              {picklistDocType === 'external' && <>
                <Typography>Vendor Name</Typography>
                <InputBase
                  data-tip
                  data-for="title"
                  placeholder="Vendor Name"
                  className="outlined border rounded px-2 mx-1"
                  name="title"
                  value={vendorName}
                  onChange={(e) => setVendorName(e.target.value)}
                  onBlur={() => {}}
                  sx={{ flex: 1, width: "100%" }}
                />

                <Typography>Suburb/ State</Typography>
                  <InputBase
                    data-tip
                    data-for="title"
                    placeholder="Vendor Name"
                    className="outlined border rounded px-2 mx-1"
                    name="title"
                    value={suburbAndState}
                    onChange={(e) => setSuburbAndState(e.target.value)}
                    onBlur={() => {}}
                    sx={{ flex: 1, width: "100%" }}
                  />

                <Typography sx={{ marginTop: "1em" }}>Vendor Email</Typography>
                <InputBase
                  data-tip
                  data-for="title"
                  placeholder="Vendor Name"
                  className="outlined border rounded px-2 mx-1"
                  name="title"
                  value={vendorEmail}
                  onChange={(e) => setVendorEmail(e.target.value)}
                  onBlur={() => {}}
                  sx={{ flex: 1, width: "100%" }}
                />
              </>}

              <Typography sx={{ marginTop: "1em" }}>
                Our PO/ Your Quote
              </Typography>
              <InputBase
                data-tip
                data-for="title"
                placeholder="Quote Number"
                className="outlined border rounded px-2 mx-1"
                name="title"
                value={quoteReferenceNumber}
                onChange={(e) => setQuoteReferenceNumber(e.target.value)}
                onBlur={() => {}}
                sx={{ flex: 1, width: "100%" }}
              />

            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography>Calculate Nuts and Bolts for Cantilever</Typography>
              <GreenSwitch
                checked={calculateNutsAndBoltsEnabled}
                onChange={(e) => setCalculateNutsAndBoltsEnabled(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Box>

              {Array.apply(null, {length: quotationSections[0].sectionCount}).map((section, sectionIndex) => {
                
                return <Box key={sectionIndex}>{<Typography mt='1em'>Section - {sectionIndex + 1}</Typography>}{quotationParts?.map((part, index) => {
                  return (
                    part?.name &&
                    part.section === sectionIndex + 1 && (
                      <PicklistPart
                        key={index}
                        quotationParts={quotationParts}
                        part={part}
                        setQuotationPartsUpdated={setQuotationPartsUpdated}
                        picklistDocType={picklistDocType}
                      />
                    )
                  );
                })}
                </Box>
              })}
            </Box>

            <Box display="flex" justifyContent="space-between" mt="2em">
              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>

              <Button
                sx={{
                  backgroundColor: colors.blueAccent[700],
                  color: colors.grey[100],
                  fontSize: "14px",
                  fontWeight: "bold",
                  padding: "10px 20px",
                }}
                onClick={saveFile}
              >
                Confirm
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
      <Button
        sx={{
          backgroundColor: colors.blueAccent[700],
          color: colors.grey[100],
          fontSize: "14px",
          fontWeight: "bold",
          padding: "5px 20px",
          mt: "1em",
          mr: "1em",
        }}
        disabled={isLoading || disableDownloadButton}
        onClick={() => setOpenPickListModal(true)}
      >
        {!isLoading ? (
          "Download Picklist"
        ) : (
          <Spinner
            animation="border"
            role="status"
            size="sm"
            variant="primary"
          />
        )}
      </Button>
    </>
  );
}
