import { Box, Button, Checkbox, FormControlLabel, MenuItem, Select, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import BasicDatePicker from "./BasicDatePicker";
import * as Parse from "parse";
import { tokens } from "../theme";
import dayjs from 'dayjs';
import { Spinner } from "react-bootstrap";
import { PieChart } from '@mui/x-charts/PieChart';
import { ARM_NAME, BASE_NAME, BRACE_NAME, COLUMN_NAME } from "./CantileverPart";


const ProductsSellingReport = () => {
    const theme = useTheme();
    const currentUser = Parse.User.current();
    const [loading, setLoading] = useState(false);
    const colors = tokens(theme.palette.mode);
    const currentDate = dayjs(new Date());
    const last14DaysDate = currentDate.subtract(1, 'month');
    const [selectedFromDate, setSelectedFromDate] = useState(last14DaysDate);
    const [selectedToDate, setSelectedToDate] = useState(dayjs());
    const [allQuotationProducts, setAllQuotationProducts] = useState([]);
    const [productsWithQuantity, setProductsWithQuantity] = useState([]);
    const [selectedDuty, setSelectedDuty] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedProductClass, setSelectedProductClass] = useState('');
    const [productsPieChartData, setProductsPieChartData] = useState([]);
    const [isHidden, setIsHidden] = useState(false);
    const [isHiddenFirst, setIsHiddenFirst] = useState(false);
    const [sizesPieChartData, setSizesPieChartData] = useState([]);
    const [furtherBreakDown, setFurtherBreakDown] = useState('');
    const [furtherBreakDownFinish, setFurtherBreakDownFinish] = useState('');

    const handleDutyChange = (event) => {
        setSelectedDuty(event.target.value);
    };


    const queryQuotationsRelativeToDateRange = async () => {
        try {
            setProductsPieChartData([]);
            setSizesPieChartData([]);
            setLoading(true);
            const quotationsQuery = new Parse.Query(Parse.Object.extend("Quotations"));
            quotationsQuery.equalTo('status', selectedStatus);
            quotationsQuery.lessThanOrEqualTo("createdAt", new Date(selectedToDate));
            quotationsQuery.greaterThanOrEqualTo("createdAt", new Date(selectedFromDate));
            const quotations = await quotationsQuery.find();
            const allPartsDictionary = {};
            quotations.map((quote) => {
                return allPartsDictionary[quote.id] ? allPartsDictionary[quote.id].parts.push(quote.get('quotationParts')) : allPartsDictionary[quote.id] = { quote: quote, parts: quote.get('quotationParts') };
            });


            let newQuotations = {}
            Object.keys(allPartsDictionary).map((key) => {
                let updatedList = []
                allPartsDictionary[key].parts.map(object => {
                    return updatedList.push({ ...object, quotationId: key, quotationStatus: allPartsDictionary[key].quote.get("status"), quotationNumber: allPartsDictionary[key].quote.get("quotationNumber"), winProbability: allPartsDictionary[key].quote.get("winProbability"), quotationTotal: allPartsDictionary[key].quote.get("totalPrice") })
                })
                return newQuotations[key] = updatedList;
            });
            setAllQuotationProducts(Object.values(newQuotations).flat());
        } catch (error) {
            console.log('error: ', error);
            setLoading(false);
        }
    }

    useEffect(() => {
        if (allQuotationProducts.length > 0) {
            setLoading(false);
            let objectMap = {};
            allQuotationProducts.filter((product) => (product.className === selectedProductClass && (product.className === 'CantileverParts' ? product.duty === selectedDuty : true))).forEach((product, index) => {


                const key = product.name; // Use partID directly

                if (objectMap[key] !== undefined) {
                    // If the product already exists, add to the quantity and append the quotation number
                    objectMap[key].quantity += product.quantity;
                    objectMap[key].quotations.push(product.quotationNumber);
                } else {
                    // If the product doesn't exist, create a new entry
                    objectMap[key] = {
                        part: product,
                        quantity: product.quantity,
                        id: index,
                        quotations: [product.quotationNumber],
                        forecast: [product?.winProbability] // Optional, keep if needed
                    };
                }
            });
            const _productsPieChartData = [];

            Object.keys(objectMap).map((k, index) => {
                _productsPieChartData.push({

                    id: index,
                    value: objectMap[k].quantity,
                    label: k,
                })
            })
            setProductsPieChartData(_productsPieChartData)
            setProductsWithQuantity(objectMap);
        }
    }, [allQuotationProducts]);

    useEffect(() => {
        if (furtherBreakDown.length > 0 || furtherBreakDownFinish.length > 0) {
            const _productSizePieChart = [];
            if (allQuotationProducts.length > 0) {
                setLoading(false);
                let objectMap = {};
                allQuotationProducts.filter((product) => (product.className === selectedProductClass && (product.className === 'CantileverParts' ? product.duty === selectedDuty : true) && product.name === furtherBreakDown && (furtherBreakDownFinish.length > 0 ? product.finish === furtherBreakDownFinish : product.finish))).forEach((product, index) => {


                    const key = product.partID; // Use partID directly

                    if (objectMap[key] !== undefined) {
                        // If the product already exists, add to the quantity and append the quotation number
                        objectMap[key].quantity += product.quantity;
                        objectMap[key].quotations.push(product.quotationNumber);
                    } else {
                        // If the product doesn't exist, create a new entry
                        objectMap[key] = {
                            part: product,
                            quantity: product.quantity,
                            id: index,
                            quotations: [product.quotationNumber],
                            forecast: [product?.winProbability] // Optional, keep if needed
                        };
                    }
                });

                Object.keys(objectMap).map((k, index) => {
                    let cantiLabel = null;

                    if (objectMap[k] && objectMap[k].part) {
                        const part = objectMap[k].part;

                        if (part.name === COLUMN_NAME) {
                            cantiLabel = `${part.height || ''} High / ${part.width || ''}`.trim() + " " + part.finish;
                        } else if (part.name === BASE_NAME) {
                            cantiLabel = `${part.sides === "1" ? 'S/S' : 'D/S'} for ${part.armToSuitBase || ''} Arms / ${part.duty === 'Light' ? '200' :
                                part.duty === 'Medium' ? '305' :
                                    part.duty === 'Heavy' ? '350' : ''
                                } (${part.width || ''})`.trim() + " " + part.finish;
                        } else if (part.name === ARM_NAME) {
                            cantiLabel = `H${part.height || ''} ${part.depth || ''}`.trim() + " " + part.finish;
                        } else if (part.name === BRACE_NAME && part.bayCenter) {
                            const [orientation, center] = part.bayCenter.split(" ");
                            cantiLabel = `${orientation === "Horizontal" ? "H" : 'D'} ${center || ''} c/c (${part.width || ''})`.trim() + " " + part.finish;
                        }
                    }

                    return _productSizePieChart.push({
                        id: index,
                        value: objectMap[k].quantity,
                        label: objectMap[k].part.className === 'CantileverParts' ? cantiLabel : objectMap[k].part.description
                    });
                })
            }
            setSizesPieChartData(_productSizePieChart)
        }
    }, [furtherBreakDown, furtherBreakDownFinish]);

    return (
        <Box>
            {(currentUser.get('email') === "sunil@storemax.com.au" || currentUser.get('email') === "wahabtariq55@gmail.com" || currentUser.get('email') === "operations@storemax.com.au") && <Box>
                <Box display={'flex'} >
                    <Box mx={1}>
                        <BasicDatePicker date={selectedFromDate} setDate={setSelectedFromDate} label={"From Date"} />
                    </Box>
                    <Box mx={1}>
                        <BasicDatePicker date={selectedToDate} setDate={setSelectedToDate} label={"To Date"} />
                    </Box>
                    {/* 'in-process', 'open', 'won', 'hold', 'deposit', 'cancelled' */}
                    <Select
                        sx={{ mx: 1 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedStatus}
                        displayEmpty
                        label="Quotation Status"
                        onChange={(e) => setSelectedStatus(e.target.value)}
                    >
                        <MenuItem value={''}>Select a Status</MenuItem>
                        <MenuItem value={'in-process'}>In-Process</MenuItem>
                        <MenuItem value={'open'}>Open</MenuItem>
                        <MenuItem value={'won'}>Won</MenuItem>
                        <MenuItem value={'deposit'}>Deposit</MenuItem>
                        <MenuItem value={'hold'}>Hold</MenuItem>
                        <MenuItem value={'cancelled'}>Cancelled</MenuItem>
                    </Select>
                    <Select
                        sx={{ mx: 1 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedProductClass}
                        displayEmpty
                        label="Product"
                        onChange={(e) => setSelectedProductClass(e.target.value)}
                    >
                        <MenuItem value={''}>Select a Product</MenuItem>
                        <MenuItem value={'CantileverParts'}>Cantilever</MenuItem>
                        <MenuItem value={'PalletRackingParts'}>Pallet Rack</MenuItem>
                        <MenuItem value={'PalletRackingPartsUsed'}>Pallet Rack (Used)</MenuItem>
                    </Select>
                    <Select
                        sx={{ mx: 1 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedDuty}
                        displayEmpty
                        label="Duty"
                        onChange={handleDutyChange}
                    >
                        <MenuItem value={''}>Select a Duty</MenuItem>
                        <MenuItem value={'Light'}>Light</MenuItem>
                        <MenuItem value={'Medium'}>Medium</MenuItem>
                        <MenuItem value={'Heavy'}>Heavy</MenuItem>
                    </Select>
                    <Box mx={1}>
                        <Button
                            sx={{
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "5px 20px",
                                mt: '1em'
                            }}
                            disabled={selectedStatus.length === 0 || selectedProductClass.length === 0 || selectedDuty.length === 0}
                            onClick={queryQuotationsRelativeToDateRange}
                        >
                            GO
                        </Button>
                    </Box>
                </Box>
                {!loading ? <Box>
                    <Box width={"100%"} display={'flex'} justifyContent={'center'}>
                        {Object.values(productsWithQuantity).length > 0 && <>
                            <FormControlLabel
                                checked={isHiddenFirst}
                                control={
                                    <Checkbox onChange={(event) => setIsHiddenFirst(event.target.checked)} />
                                }
                                label="hide the legend"
                                labelPlacement="end"
                            />
                            <PieChart
                                sx={{ mt: 2 }}
                                slotProps={{ legend: { hidden: isHiddenFirst } }}
                                series={[
                                    {
                                        data: productsPieChartData,
                                        innerRadius: 30,
                                        paddingAngle: 5,
                                        cornerRadius: 5,
                                        outerRadius: 150,
                                        startAngle: -45,
                                        endAngle: 360,
                                    }
                                ]}
                                width={950}
                                height={400}
                            />
                        </>}
                    </Box>
                    <Box display={'flex'} justifyContent={'space-around'}>
                        {productsPieChartData.length > 0 && <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} alignItems={'center'}>
                            <Typography>Select Part </Typography>
                            <Box display={'flex'} justifyContent={'center'}>
                                <Select
                                    sx={{ mx: 1 }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={furtherBreakDown}
                                    displayEmpty
                                    label="Further BreakDown"
                                    onChange={(e) => setFurtherBreakDown(e.target.value)}
                                >
                                    {productsPieChartData.length === 0 && <MenuItem value={''}>Break it down further</MenuItem>}
                                    {productsPieChartData.map((item, index) => (
                                        <MenuItem key={index} value={item.label}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Box>}
                        {productsPieChartData.length > 0 && <Box display={'flex'} justifyContent={'center'} mt={2}>
                            <Select
                                sx={{ mx: 1 }}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={furtherBreakDownFinish}
                                displayEmpty
                                label="Further BreakDown"
                                onChange={(e) => setFurtherBreakDownFinish(e.target.value)}
                            >
                                {productsPieChartData.length === 0 && <MenuItem value={''}>Break it down further</MenuItem>}
                                <MenuItem value={''}>Select a Finish (All)</MenuItem>
                                <MenuItem value={'Blue'}>Powder Coated</MenuItem>
                                <MenuItem value={'Galv'}>Galvanized</MenuItem>
                            </Select>
                        </Box>}
                    </Box>
                    {sizesPieChartData.length > 0 && <Box display={'flex'} justifyContent={'center'}>
                        {Object.values(productsWithQuantity).length > 0 && <>
                            <FormControlLabel
                                checked={isHidden}
                                control={
                                    <Checkbox onChange={(event) => setIsHidden(event.target.checked)} />
                                }
                                label="hide the legend"
                                labelPlacement="end"
                            />
                            <PieChart
                                slotProps={{ legend: { hidden: isHidden } }}
                                series={[
                                    {
                                        data: sizesPieChartData,
                                        innerRadius: 30,
                                        outerRadius: 150,
                                        paddingAngle: 5,
                                        cornerRadius: 5,
                                    }
                                ]}
                                width={950}
                                height={400}
                            />
                        </>}
                    </Box>}
                </Box> :
                    <Box textAlign={'center'} mt={3}>
                        <Spinner animation="border" role="status" size='sm' variant="primary" />
                    </Box>}
            </Box>}
        </Box>
    )
}

export default ProductsSellingReport;